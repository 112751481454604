/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitiesAgGridModule } from '@wilson/activities/ag-grid';
import { FormsModule as WilsonFormsModule } from '@wilson/forms';
import {
  FormatDistanceFromTodayPipeModule,
  LocationNamePipe,
  PipesModule,
} from '@wilson/pipes';
import { ExpiryDateHelperService } from '@wilson/share/services';
import {
  SharedComponentsModule,
  ViaPointsToolTipModule,
} from '@wilson/shared-components';
import { TooltipModule } from '@wilson/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ShowTooltipRendererComponent } from '../common/show-tooltip.renderer';
import { AddOrRemoveCheckBoxRendererComponent } from '../payroll-settings/add-or-remove-check-box.renderer';
import { PayrollSettingsIconRendererComponent } from '../payroll-settings/payroll-settings-icon.renderer';
import { TrashIconRendererComponent } from '../payroll-settings/trash-icon.renderer';
import { AssignNameRendererComponent } from '../services/assign-name.renderer';
import { NameRendererComponent } from '../services/name.renderer';
import { PrefScoreRendererComponent } from '../services/prefScore.renderer';
import { QualificationFulfilledRendererComponent } from '../services/qualification-fulfilled.renderer';
import { SmartScoreRendererComponent } from '../services/smartScore.renderer';
import { TransferTimeRendererComponent } from '../services/transfer-time-renderer';
import { WeekDaysRendererComponent } from '../services/weekdays.renderer';
import { DurationRendererComponent } from '../shift-templates/duration.renderer';
import { ShiftTemplateInfoIconRendererComponent } from '../shift-templates/shift-template-info-icon.renderer';
import { ShiftTemplateLinkCountComponent } from '../shift-templates/shift-template-link-count/shift-template-link-count.component';
import { ShiftTemplateNameRendererComponent } from '../shift-templates/shift-template-name.renderer';
import { TimeRendererComponent } from '../shift-templates/time.renderer';
import { ValidationRenderComponent } from '../shifts/validation.renderer';
import { WilsonLearnRendererComponent } from '../shifts/wilson-learn-renderer';
import { RemainingDaysRendererComponent } from '../vacation-management/remaining-days.renderer';
import { VacationDayEditService } from '../vacation-management/vacation-day-edit.service';
import { VacationDaysEditRendererComponent } from '../vacation-management/vacation-days-edit.renderer';
import { VacationDaysRendererComponent } from '../vacation-management/vacation-days.renderer';
import { VacationHeaderRendererComponent } from '../vacation-management/vacation-header.renderer';
import { ActivityCategoryAndNameRendererComponent } from './activity-category-and-name-renderer/activity-category-and-name-renderer.component';
import { ActivityServiceLinkRendererComponent } from './activity-service-link-renderer/activity-service-link-renderer.component';
import { ActivityTypeIconRendererComponent } from './activity-type-icon-renderer/activity-type-icon-renderer.component';
import { ClientAgreementRendererComponent } from './client-agreement-renderer/client-agreement-renderer.component';
import { ClientRequestStatusRendererComponent } from './client-request-status-renderer/client-request-status-renderer.component';
import { IconActionRendererComponent } from './icon-action-renderer/icon-action-renderer.component';
import { JobNameLinkComponent } from './job-name-link/job-name-link.component';
import { JobTypeRendererComponent } from './job-type-renderer/job-type-renderer.component';
import { LinkRendererContentComponent } from './link-renderer/link-renderer-content/link-renderer-content.component';
import { LinkRendererComponent } from './link-renderer/link-renderer.component';
import { OpReportActionsRendererComponent } from './operative-reports/op-report-actions-renderer/op-report-actions-renderer.component';
import { OpReportCategoryRendererComponent } from './operative-reports/op-report-category-renderer/op-report-category-renderer.component';
import { OpReportDescriptionRendererComponent } from './operative-reports/op-report-description-renderer/op-report-description-renderer.component';
import { OpReportOccuredTimeRendererComponent } from './operative-reports/op-report-occured-time-renderer/op-report-occured-time-renderer.component';
import { OpReportPriorityRendererComponent } from './operative-reports/op-report-priority-renderer/op-report-priority-renderer.component';
import { OpReportStaffRendererComponent } from './operative-reports/op-report-staff-renderer/op-report-staff-renderer.component';
import { PlannedAndReportedTimeRendererComponent } from './planned-and-reported-time-renderer/planned-and-reported-time-renderer.component';
import { ProviderAcceptanceStatusRendererComponent } from './provider-acceptance-status-renderer/provider-acceptance-status-renderer.component';
import { ProviderAnswerStatusRendererComponent } from './provider-answer-status-renderer/provider-answer-status-renderer.component';
import { QualificationLinkRendererComponent } from './qualification-link-renderer/qualification-link-renderer.component';
import { RailMLValidityRendererComponent } from './railml-validity-renderer/railml-validity.renderer';
import { RedirectingServiceLinkRendererComponent } from './redirecting-service-link-renderer/redirecting-service-link-renderer.component';
import { RedirectingShiftLinkRendererComponent } from './redirecting-shift-link-renderer/redirecting-shift-link-renderer.component';
import { ShareJobCreatedAtRendererComponent } from './share-job-created-at-renderer/share-job-created-at-renderer.component';
import { ShareJobCreatedAtComponent } from './share-job-created-at/share-job-created-at.component';
import { ShareJobExpiredInComponent } from './share-job-expired-in/share-job-expired-in.component';
import { ShareJobExpiresInRendererComponent } from './share-job-expires-in-renderer/share-job-expires-in-renderer.component';
import { ShareSelectTooltipComponent } from './share-select-tooltip/share-select-tooltip.component';
import { ShiftNameRendererComponent } from './shift-name-renderer/shift-name-renderer.component';
import { UserAvatarRenderer } from './user-avatar-renderer/user-avatar.renderer';
import { WilsonShareJobLinkRendererComponent } from './wilson-share-job-link-renderer/wilson-share-job-link-renderer.component';
import { ActivityStatusRendererComponent } from './activity-status-renderer/activity-status-renderer.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { ServicesLinkRendererWithIconComponent } from './link-renderer/services-link-renderer-with-icon.component';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { ShiftLinkRendererWithIconComponent } from './shift-link-renderer-with-icon/shift-link-renderer-with-icon.component';
import { ActivityPlaningStatusRendererComponent } from './activity-planing-status-renderer/activity-planing-status.renderer';
import { UserNamePipeModule } from '@wilson/account';
@NgModule({
  declarations: [
    ShiftTemplateNameRendererComponent,
    ShiftTemplateInfoIconRendererComponent,
    DurationRendererComponent,
    ShowTooltipRendererComponent,
    TimeRendererComponent,
    VacationHeaderRendererComponent,
    VacationDaysRendererComponent,
    RemainingDaysRendererComponent,
    VacationDaysEditRendererComponent,
    IconActionRendererComponent,
    LinkRendererComponent,
    LinkRendererContentComponent,
    ServicesLinkRendererWithIconComponent,
    PlannedAndReportedTimeRendererComponent,
    ShiftTemplateLinkCountComponent,
    QualificationLinkRendererComponent,
    PayrollSettingsIconRendererComponent,
    AddOrRemoveCheckBoxRendererComponent,
    TrashIconRendererComponent,
    UserAvatarRenderer,
    JobNameLinkComponent,
    ClientRequestStatusRendererComponent,
    ShareJobCreatedAtComponent,
    ShareJobCreatedAtRendererComponent,
    ShareJobExpiredInComponent,
    ShareJobExpiresInRendererComponent,
    ProviderAnswerStatusRendererComponent,
    ProviderAcceptanceStatusRendererComponent,
    ActivityCategoryAndNameRendererComponent,
    ActivityServiceLinkRendererComponent,
    WilsonShareJobLinkRendererComponent,
    ActivityTypeIconRendererComponent,
    TransferTimeRendererComponent,
    SmartScoreRendererComponent,
    QualificationFulfilledRendererComponent,
    PrefScoreRendererComponent,
    NameRendererComponent,
    AssignNameRendererComponent,
    OpReportActionsRendererComponent,
    OpReportDescriptionRendererComponent,
    OpReportOccuredTimeRendererComponent,
    OpReportPriorityRendererComponent,
    OpReportCategoryRendererComponent,
    OpReportStaffRendererComponent,
    JobTypeRendererComponent,
    WeekDaysRendererComponent,
    ShareSelectTooltipComponent,
    RailMLValidityRendererComponent,
    ValidationRenderComponent,
    WilsonLearnRendererComponent,
    LinkRendererContentComponent,
    ClientAgreementRendererComponent,
    ShiftNameRendererComponent,
    RedirectingServiceLinkRendererComponent,
    RedirectingShiftLinkRendererComponent,
    ActivityStatusRendererComponent,
    ShiftLinkRendererWithIconComponent,
    ActivityPlaningStatusRendererComponent,
  ],
  imports: [
    CommonModule,
    FeatureFlagsModule,
    FormsModule,
    TranslateModule,
    NzToolTipModule,
    NzButtonModule,
    FontAwesomeModule,
    RouterModule,
    ViaPointsToolTipModule,
    SharedComponentsModule,
    FormatDistanceFromTodayPipeModule,
    ActivitiesAgGridModule,
    TooltipModule,
    NzPopconfirmModule,
    PipesModule,
    WilsonFormsModule,
    NzTagModule,
    FontAwesomeModule,
    NzTypographyModule,
    UserNamePipeModule,
  ],
  exports: [
    ActivityCategoryAndNameRendererComponent,
    ShiftTemplateNameRendererComponent,
    ShiftTemplateInfoIconRendererComponent,
    DurationRendererComponent,
    ShowTooltipRendererComponent,
    TimeRendererComponent,
    VacationHeaderRendererComponent,
    VacationDaysRendererComponent,
    RemainingDaysRendererComponent,
    VacationDaysEditRendererComponent,
    IconActionRendererComponent,
    LinkRendererComponent,
    LinkRendererContentComponent,
    ServicesLinkRendererWithIconComponent,
    PlannedAndReportedTimeRendererComponent,
    ShiftTemplateLinkCountComponent,
    PayrollSettingsIconRendererComponent,
    AddOrRemoveCheckBoxRendererComponent,
    TrashIconRendererComponent,
    UserAvatarRenderer,
    JobNameLinkComponent,
    ClientRequestStatusRendererComponent,
    ShareJobCreatedAtComponent,
    ShareJobCreatedAtRendererComponent,
    ShareJobExpiredInComponent,
    ShareJobExpiresInRendererComponent,
    ProviderAnswerStatusRendererComponent,
    ProviderAcceptanceStatusRendererComponent,
    OpReportActionsRendererComponent,
    OpReportDescriptionRendererComponent,
    OpReportOccuredTimeRendererComponent,
    OpReportPriorityRendererComponent,
    OpReportCategoryRendererComponent,
    OpReportStaffRendererComponent,
    RailMLValidityRendererComponent,
    ValidationRenderComponent,
    WilsonLearnRendererComponent,
    ActivityStatusRendererComponent,
    ShiftLinkRendererWithIconComponent,
    ActivityPlaningStatusRendererComponent,
  ],
  providers: [
    VacationDayEditService,
    ExpiryDateHelperService,
    LocationNamePipe,
  ],
})
export class AgGridRenderersModule {}
