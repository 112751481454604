import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
} from '@angular/core';
import { User } from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'user-avatar-renderer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <wilson-user-avatar
      [routerLink]="routerLink"
      (click)="ignoreWhenLinked($event)"
      [s3Urn]="cellValue"
      [fallbackImage]="fallbackImage"
    ></wilson-user-avatar>
  `,
  styles: [
    `
      :host {
        height: 40px;
        width: 40px;
      }
    `,
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UserAvatarRenderer implements AgRendererComponent {
  @HostBinding('class') cssClassName = 'd-flex justify-content-center';

  // eslint-disable-next-line
  public routerLink: any[] | string | null | undefined;
  public cellValue: string;

  public fallbackImage = 'assets/img/profile/profile-img-placeholder-dark.svg';
  public fallbackIcon: boolean | string | undefined;

  constructor(private cd: ChangeDetectorRef) {}

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);

    if (params['linkToUser']) {
      this.routerLink =
        '/settings/accounts/' +
        params.data[
          typeof params['linkToUser'] === 'string' ? params['linkToUser'] : 'id'
        ];
    }

    if (params['fallbackImage']) {
      this.fallbackImage = params['fallbackImage'];
    }
    if (params['fallbackIcon']) {
      this.fallbackIcon = params['fallbackIcon'];
    }
  }

  refresh(params: ICellRendererParams): boolean {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  public ignoreWhenLinked(event: MouseEvent): false | void {
    if (this.routerLink) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  public getValueToDisplay(params: ICellRendererParams) {
    const value: string | User = params.valueFormatted
      ? params.valueFormatted
      : params.value;
    return typeof value === 'string' ? value : value?.profileImageUrl;
  }
}
