import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@wilson/config';
import { NotificationTimerService } from './notification-timer.service';
import { NotificationsService } from './notifications.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, ConfigModule],
  providers: [NotificationsService, NotificationTimerService],
})
export class NotificationsModule {}
