import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  Sector,
  TimelineStayManagementDrawerFormValue,
} from '@wilson/interfaces';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { StaysDrawerHelperService } from '../services/stays-drawer-helper.service';
import { StaysDrawerFormComponent } from '../stays-drawer-form/stays-drawer-form.component';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'wilson-create-stays-drawer-view',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzDrawerModule,
    NzButtonModule,
    StaysDrawerFormComponent,
  ],
  templateUrl: './create-stays-drawer-view.component.html',
  styleUrl: './create-stays-drawer-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateStaysDrawerViewComponent {
  @Input({
    required: true,
  })
  sector!: Sector | null | undefined;

  @Input()
  showMask = false;

  @Input()
  disableButtons = false;

  @Input()
  isSaving = false;

  @Input()
  isUserFieldReadOnly!: boolean;

  @Input() prefillValues:
    | Partial<TimelineStayManagementDrawerFormValue>
    | undefined = undefined;

  @Output()
  confirm = new EventEmitter<Partial<TimelineStayManagementDrawerFormValue>>();

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  handleInvoice = new EventEmitter<NzUploadFile[]>();

  @Output()
  handleAttachments = new EventEmitter<NzUploadFile[]>();

  protected isFormInvalidSignal = signal(false);
  protected formValueSignal = signal<
    Partial<TimelineStayManagementDrawerFormValue>
  >({});

  constructor(
    protected readonly staysDrawerHelperService: StaysDrawerHelperService,
  ) {}

  closeDrawer() {
    this.cancel.emit();
  }

  emitConfirmEvent() {
    this.confirm.emit(this.formValueSignal());
  }
}
