import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OperativeReportCategory } from '@wilson/operative-reports/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { minutesToHours } from 'date-fns';

@Component({
  selector: 'wilson-op-report-description-renderer',
  templateUrl: './op-report-description-renderer.component.html',
  styles: [
    `
      .comment {
        font-style: normal;
        font-weight: 400;
        color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
      }

      .no-comment {
        color: var(--grey-6);
      }
    `,
  ],
})
export class OpReportDescriptionRendererComponent
  implements AgRendererComponent
{
  comment!: string;
  operativeReportCategory = OperativeReportCategory;
  category!: string;
  time!: string;

  constructor(private readonly translate: TranslateService) {}

  agInit(params: ICellRendererParams): void {
    this.comment = params.value;
    this.category = params.data.categoryId;
    this.category === OperativeReportCategory.Delay
      ? this.getDelayTime(params)
      : '';
  }

  refresh(params: ICellRendererParams) {
    this.comment = params.value;
    this.category = params.data.categoryId;
    this.category === OperativeReportCategory.Delay
      ? this.getDelayTime(params)
      : '';
    this.getDelayTime(params);
    return true;
  }

  getDelayTime(params: ICellRendererParams) {
    const timeInMinutes = params?.data?.delayInMinutes;
    const minutes = timeInMinutes % 60;
    const hours = minutesToHours(timeInMinutes);
    const hours_translation = this.translate.instant('page.overtime.hour');
    const singleHour_translation = this.translate.instant(
      'page.settings.working_time_models.hour',
    );
    const minute_translation = this.translate.instant('page.overtime.minutes');
    this.time =
      minutes > 0
        ? `${hours} ${
            hours > 1 ? hours_translation : singleHour_translation
          } ${minutes} ${minute_translation}`
        : `${hours} ${hours > 1 ? hours_translation : singleHour_translation}`;
  }
}
