import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { Activity } from '../activities/activity';
import { OperationStatus } from '../activities/operationStatus';
import { Shift, WithPreparedAttributes } from '../shifts/shift';
import { Absence } from '../absences/absence';
import { User } from '../accounts/User';
import { UserRole } from '../authorization/role';
import { ActivityDeviationStatus } from '../activities/DeviationStatus';
import { ServiceDeviation } from '@wilson/service-deviation/interfaces';
import { ActivityReport } from '../activities/activity-report';
import { ActivityCategory } from '../activities/activity-category';
import { GeoLocation } from '../locations/geoLocation';
import { Stay } from '../stays/stays';

export interface AccurateDateInterval {
  accurateStartDateTime: Date;
  accurateEndDateTime: Date;
}

export type ActivityWithOverlapLevel = (Activity & {
  overlapLevel: number;
  id: string;
  operationalStatus: OperationStatus;
})[];

export interface ItemWithActivities {
  id: string;
  overlapLevel: number;
  activities: (Activity & { id: string })[];
}

export type ShiftWithActivitiesAndOverlap = Shift & {
  id: string;
  startDatetime: Date;
  endDatetime: Date;
  activities: ActivityWithOverlapLevel[];
};

export interface DraftShift {
  isDraft: boolean;
}

export interface ItemViewModel {
  activitiesInRowLevels: ActivityWithOverlapLevel[];
  shift: Shift & {
    id: string;
    startDatetime: Date;
    endDatetime: Date;
    activities: Activity[];
    priority: number;
  };
}
export type ShiftWithUpdatedActivities = Shift & {
  id: string;
  startDatetime: Date;
  endDatetime: Date;
  activities: ActivityWithOverlapLevel[];
  activitiesInRowLevels: ActivityWithOverlapLevel[];
  overlapLevel: number;
};

export interface GroupedUserData {
  rowHeightInPixels: number;
  itemViews: ItemViewModel[];
  absences: Absence[];
}

export type ItemViewModelWithOverlapLevel = ItemViewModel & {
  overlapLevel: number;
};

export type TimelineShiftForm = FormGroup<
  ControlsOf<
    Pick<Shift, 'name' | 'comment' | 'shiftCategoryId' | 'organizationalUnitId'>
  >
>;

type AbsenceId = string;
type ShiftId = string;
type StayId = string;
type ActivityId = string;
export type ActivityWithCategoryAndLocations = Activity & {
  activityCategory: ActivityCategory;
  startLocation: GeoLocation;
  endLocation: GeoLocation;
};
export type ActivityRow = Record<ActivityId, ActivityWithCategoryAndLocations>;
export type UserTimelinesWithActivitiesPayload = User & {
  userRoles: UserRole[];
  shifts: (Shift &
    WithPreparedAttributes & {
      id: string;
      activities: Activity[];
      priority: number | null;
    })[];
  absences: (Absence & { id: string })[];
};

export type UserTimelinesWithoutActivitiesPayload = User & {
  userRoles: UserRole[];
  shifts: (Shift &
    WithPreparedAttributes & {
      id: string;
    })[];
  absences: (Absence & { id: string })[];
};

export type UserTimelines = User & {
  userRoles: UserRole[];
  shiftsWithoutActivitiesDictionary: Record<
    ShiftId,
    Shift &
      WithPreparedAttributes & {
        id: string;
      }
  >;
  absencesDictionary: Record<AbsenceId, Absence & { id: string }>;
  staysDictionary: Record<StayId, Stay>;
  determinedRowDataV2: GroupedUserDataV2[];
  determinedRowDataTimestamp: number;
};

export type UserTimelinesModal = User & {
  userRoles: UserRole[];
  shifts: (Shift &
    WithPreparedAttributes & {
      id: string;
      activities: Activity[];
      priority: number | null;
    })[];
};

export interface DemarcatorRange {
  left: number | null | undefined;
  right: number | null | undefined;
}

export interface AccurateTimeDetails {
  date: string;
  location: {
    locationCode: string;
    name: string;
  };
  type: ActivityDeviationStatus;
  timeDifference: number;
}

export interface AccurateDateIntervalWithAbsence extends AccurateDateInterval {
  absence: Absence & { id: string };
}

export interface AccurateDateIntervalWithStay extends AccurateDateInterval {
  stay: Stay;
}

export interface AccurateDateIntervalWithShift extends AccurateDateInterval {
  shift: Shift &
    WithPreparedAttributes & {
      id: string;
    };
}
export type AccurateDateIntervalWithActivity = Activity & AccurateDateInterval;

export type AccurateUnassignedItem = AccurateDateInterval & {
  id: string;
  activities: Activity[];
};

export interface GroupedUnassignedUserDataV2 {
  services: AccurateUnassignedItem[];
  jobs: AccurateUnassignedItem[];
}
export interface GroupedUserDataV2 {
  shifts: AccurateDateIntervalWithShift[];
  absences: AccurateDateIntervalWithAbsence[];
  stays: AccurateDateIntervalWithStay[];
}

export const TimelineDateQueryParamKey = 'timelineDate';
export const ZoomLevelQueryParamKey = 'timelineZoomLevel';
export const TimelineShowActivitiesQueryParamKey = 'showActivities';
export const TimelineShowStaysQueryParamKey = 'showStays';
export const TimelineUnassignedShiftIdsQueryParamKey = 'unassignedShiftIds';
export const TimelineUnassignedShiftSearchesQueryParamKey =
  'unassignedShiftSearches';
export const TimelineUnassignedServiceIdsQueryParamKey = 'unassignedServiceIds';
export const TimelineUnassignedServiceSearchesQueryParamKey =
  'unassignedServiceSearches';

export enum ZOOM_LEVEL {
  ZOOMED_IN = 'ZOOMED_IN',
  DEFAULT = 'DEFAULT',
  ZOOMED_OUT = 'ZOOMED_OUT',
  ZOOMED_OUT_1 = 'ZOOMED_OUT_1',
}

export enum LayoutStyle {
  Compact = 'compact',
  Minimal = 'minimal',
}

export enum KPIOptions {
  Overtime = 'overtime',
  Productivity = 'productivity',
}

export enum SortDirection {
  Ascending,
  Descending,
}

export enum SortByOptions {
  Name = 'name',
  Profession = 'profession',
}

export interface Threshold {
  min: number | null;
  max: number | null;
}

export interface KPISetting {
  mainKPIs: KPIOptions[];
  layout: LayoutStyle;
  sortDirection: SortDirection;
  sortBy: SortByOptions;
  threshold: Threshold;
}

export interface DateStringInterval {
  startDatetime: string;
  endDatetime: string;
  serviceDeviations?: ServiceDeviation[];
  activityReports?: ActivityReport[] | null;
}

export interface DateInterval {
  startDatetime: Date;
  endDatetime: Date;
}
