import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { KPIOptions, KPISetting } from '@wilson/interfaces';
import { cloneDeep } from 'lodash';
import { SetKpiSetting } from './kpi-settings.actions';

export interface KPISettingsModel {
  kpiSettings: {
    [userId in string]: KPISetting;
  };
}

const defaults: KPISettingsModel = {
  kpiSettings: {},
};

@State<KPISettingsModel>({
  name: 'kpiSettings',
  defaults,
})
@Injectable()
export class ShiftTimelineKPISettingsState {
  static showOvertimeKpi(userId: string) {
    return createSelector(
      [ShiftTimelineKPISettingsState.getKPISetting(userId)],
      (kpiSetting: KPISetting) => {
        return kpiSetting.mainKPIs.includes(KPIOptions.Overtime);
      },
    );
  }

  static showProductivityKpi(userId: string) {
    return createSelector(
      [ShiftTimelineKPISettingsState.getKPISetting(userId)],
      (kpiSetting: KPISetting) => {
        return kpiSetting.mainKPIs.includes(KPIOptions.Productivity);
      },
    );
  }

  static getMinimumThreshold(userId: string) {
    return createSelector(
      [ShiftTimelineKPISettingsState.getKPISetting(userId)],
      (kpiSetting: KPISetting) => {
        return kpiSetting.threshold.min;
      },
    );
  }

  static getMaximumThreshold(userId: string) {
    return createSelector(
      [ShiftTimelineKPISettingsState.getKPISetting(userId)],
      (kpiSetting: KPISetting) => {
        return kpiSetting.threshold.max;
      },
    );
  }

  static getLayoutStyle(userId: string) {
    return createSelector(
      [ShiftTimelineKPISettingsState.getKPISetting(userId)],
      (kpiSetting: KPISetting) => {
        return kpiSetting.layout;
      },
    );
  }

  static getKPISetting(userId: string) {
    return createSelector(
      [ShiftTimelineKPISettingsState],
      (state: KPISettingsModel) => {
        return state.kpiSettings[userId];
      },
    );
  }

  @Action(SetKpiSetting)
  setKpiSetting(
    ctx: StateContext<KPISettingsModel>,
    { userId, kpiSetting }: SetKpiSetting,
  ) {
    const state = ctx.getState();
    const kpiSettingsToUpdate = cloneDeep(state.kpiSettings);
    kpiSettingsToUpdate[userId] = kpiSetting;
    ctx.patchState({
      kpiSettings: kpiSettingsToUpdate,
    });
  }
}
