import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsService } from './clients.service';
import { ExternalUsersService } from './external-users.service';
import { ClientPartnerShipGateway } from './client-partnership.gateway';
import { SharedUserGateway } from './shared-user.gateway';
import { SharedUsersWithYouService } from './shared-users-with-you.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    ClientsService,
    ExternalUsersService,
    ClientPartnerShipGateway,
    SharedUserGateway,
    SharedUsersWithYouService,
  ],
})
export class ClientsServicesModule {}
