import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule as Forms, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@wilson/forms';
import { StayStatus, StayStatusColor } from '@wilson/interfaces';
import { PipesModule } from '@wilson/pipes';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

@Component({
  selector: 'wilson-timeline-stays-status',
  standalone: true,
  imports: [
    CommonModule,
    Forms,
    ReactiveFormsModule,
    TranslateModule,
    NzSwitchModule,
    NzFormModule,
    NzSelectModule,
    FormsModule,
    PipesModule,
    NzTimePickerModule,
    NzInputNumberModule,
    NzInputModule,
    NzSegmentedModule,
    NzDatePickerModule,
    NzTagModule,
  ],
  templateUrl: './stays-status.component.html',
  styleUrl: './stays-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaysStatusComponent {
  @Input() selectedStayStatus: StayStatus | null = StayStatus.Open;
  @Input() isDisabled = false;

  @Output() changedStatus = new EventEmitter<StayStatus>();
  StayStatusColor = StayStatusColor;
  stayStatusArray: StayStatus[] = Object.values(StayStatus);

  protected stayStatusChanged(selectedStatus: StayStatus) {
    this.changedStatus.emit(selectedStatus);
  }
}
