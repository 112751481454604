import { KPISetting, LayoutStyle } from '@wilson/interfaces';

export class SetKpiSetting {
  static readonly type = '[KPISettings] Set KPI Setting';
  constructor(public userId: string, public kpiSetting: KPISetting) {}
}

export class SetLayoutStyle {
  static readonly type = '[KPISettings] Set Layout Style';
  constructor(public layoutStyle: LayoutStyle) {}
}
