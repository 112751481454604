import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ShiftTimelineSettingsModel } from '../interfaces';
import {
  ResetRowHeight,
  ResetShiftTimelineWidth,
  SetAreActivitiesVisible,
  SetAreStaysVisible,
  SetDefaultRowHeight,
  SetDefaultShiftHeight,
  SetZoomLevel,
} from './shift-timeline-settings.actions';
import {
  COMPACT_ROW_HEIGHT,
  COMPACT_SHIFT_HEIGHT,
  ZOOM_LEVEL,
} from '@wilson/interfaces';

export const PIXEL_EXCEPT_TIMELINE_WIDTH = 171; // This ADJUSTMENT_PIXEL is calculated from Sidebar(px) + Page Frame Padding(px) + FIlter section(px)
export const timeframeWidthByZoomLevel = new Map([
  [ZOOM_LEVEL.ZOOMED_OUT_1, 120],
  [ZOOM_LEVEL.ZOOMED_OUT, 240],
  [ZOOM_LEVEL.DEFAULT, 480],
  [ZOOM_LEVEL.ZOOMED_IN, 1920],
]);
export const RESOLUTION_1440P_WIDTH_PIXEL = 2560;

const hoursPerZoomLevel = new Map([
  [ZOOM_LEVEL.ZOOMED_OUT_1, []], // On 24 Hour View we are not showing any hours so make this array empty
  [ZOOM_LEVEL.ZOOMED_OUT, ['00:00', '08:00', '16:00', '24:00']],
  [
    ZOOM_LEVEL.DEFAULT,
    ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '24:00'],
  ],
  [
    ZOOM_LEVEL.ZOOMED_IN,
    [
      '00:00',
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00',
      '24:00',
    ],
  ],
]);

const defaults: ShiftTimelineSettingsModel = {
  zoomLevel: ZOOM_LEVEL.DEFAULT,
  hoursRange: hoursPerZoomLevel.get(ZOOM_LEVEL.DEFAULT) as string[],
  timeframeWidth: timeframeWidthByZoomLevel.get(ZOOM_LEVEL.DEFAULT) as number,
  resourcesColumnWidthPx: 224,
  defaultRowHeight: COMPACT_ROW_HEIGHT,
  defaultShiftHeight: COMPACT_SHIFT_HEIGHT,
  defaultUnassignedSectionHeight: 150,
  areActivitiesVisible: false,
  areStaysVisible: false,
};

@State({
  name: 'shiftTimelineSettings',
  defaults,
})
@Injectable()
export class ShiftTimelineSettingsState {
  @Selector()
  static defaultUnassignedSectionHeight(state: ShiftTimelineSettingsModel) {
    return state.defaultUnassignedSectionHeight;
  }

  @Selector()
  static timeframeWidth(state: ShiftTimelineSettingsModel) {
    return state.timeframeWidth;
  }

  @Selector()
  static resourcesColumnWidthPx(state: ShiftTimelineSettingsModel) {
    return state.resourcesColumnWidthPx;
  }

  @Selector()
  static settings(
    state: ShiftTimelineSettingsModel,
  ): ShiftTimelineSettingsModel {
    return state;
  }

  @Selector()
  static hoursRange(state: ShiftTimelineSettingsModel): string[] {
    return state.hoursRange;
  }

  @Selector()
  static zoomLevel(state: ShiftTimelineSettingsModel): ZOOM_LEVEL {
    return state.zoomLevel;
  }

  @Selector()
  static defaultRowHeight(state: ShiftTimelineSettingsModel): number {
    return state.defaultRowHeight;
  }

  @Selector()
  static defaultShiftHeight(state: ShiftTimelineSettingsModel): number {
    return state.defaultShiftHeight;
  }

  @Selector()
  static areActivitiesVisible(state: ShiftTimelineSettingsModel) {
    return state.areActivitiesVisible;
  }

  @Selector()
  static areStaysVisible(state: ShiftTimelineSettingsModel) {
    return state.areStaysVisible;
  }

  @Action(SetZoomLevel)
  SetZoomLevel(
    { getState, patchState }: StateContext<ShiftTimelineSettingsModel>,
    { zoomLevel }: SetZoomLevel,
  ) {
    const state = getState();

    const windowWidth = window.innerWidth;
    let timeframeWidth = timeframeWidthByZoomLevel.get(zoomLevel) as number;

    if (
      zoomLevel === ZOOM_LEVEL.ZOOMED_OUT_1 &&
      windowWidth >= RESOLUTION_1440P_WIDTH_PIXEL
    ) {
      timeframeWidth =
        (windowWidth -
          PIXEL_EXCEPT_TIMELINE_WIDTH -
          state.resourcesColumnWidthPx) /
        30;
    }

    patchState({
      zoomLevel,
      hoursRange: hoursPerZoomLevel.get(zoomLevel) as string[],
      timeframeWidth: timeframeWidth,
    });
  }

  @Action(SetAreStaysVisible)
  setAreStaysVisible(
    { patchState }: StateContext<ShiftTimelineSettingsModel>,
    { isVisible }: SetAreStaysVisible,
  ) {
    patchState({
      areStaysVisible: isVisible,
    });
  }

  @Action(SetAreActivitiesVisible)
  setAreActivitiesVisible(
    { patchState }: StateContext<ShiftTimelineSettingsModel>,
    { isVisible }: SetAreActivitiesVisible,
  ) {
    patchState({
      areActivitiesVisible: isVisible,
    });
  }

  @Action(SetDefaultRowHeight)
  setDefaultRowHeight(
    { patchState }: StateContext<ShiftTimelineSettingsModel>,
    { height }: SetDefaultRowHeight,
  ) {
    patchState({
      defaultRowHeight: height,
    });
  }

  @Action(SetDefaultShiftHeight)
  setDefaultShiftHeight(
    { patchState }: StateContext<ShiftTimelineSettingsModel>,
    { height }: SetDefaultShiftHeight,
  ) {
    patchState({
      defaultShiftHeight: height,
    });
  }

  @Action(ResetShiftTimelineWidth)
  resetShiftTimelineWidth({
    patchState,
  }: StateContext<ShiftTimelineSettingsModel>) {
    patchState({
      resourcesColumnWidthPx: defaults.resourcesColumnWidthPx,
    });
  }

  @Action(ResetRowHeight)
  resetRowHeight({ patchState }: StateContext<ShiftTimelineSettingsModel>) {
    patchState({
      defaultRowHeight: defaults.defaultRowHeight,
    });
  }
}
