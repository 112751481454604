import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService, ManyEntity } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Activity } from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable } from 'rxjs';

// Referred From BE Prisma.ActivityWhereInput from activities.controller.ts
export interface ActivitiesOptions {
  where?: PartialActivityWhereInput;
  relations?: string[];
  pageNumber?: number;
  pageSize: number;
  orderBy?: {
    startDatetime: 'asc' | 'desc';
  };
}

export interface PartialActivityWhereInput {
  startDatetime?: string | FindInOption;
  endDatetime?: string;
  operationalStatus?: string | StringFilter;
  shiftLabelIds?: FindInOption;
  shiftUserLabelIds?: FindInOption;
  shift?: {
    reportedStartDatetime?: string | FindInOption;
    state?: FindInOption;
    userId?: FindInOption;
    shiftCategoryId?: FindInOption;
    organizationalUnitId?: FindInOption;
    user?: {
      organizationalUnitId?: FindInOption;
    };
    name?: FindInOption;
  };
  activityCategoryId?: FindInOption;
  job?: {
    organizationalUnitId: FindInOption;
  };
  jobId?: FindInOption;
  agreementId?: FindInOption;
  OR?: {
    shift?: {
      name?: StringFilter;
      user?: {
        firstName?: StringFilter;
        lastName?: StringFilter;
      };
    };
    name?: StringFilter;
  }[];
  serviceId?: 'null' | 'not-null';
  shiftId?: 'null' | 'not-null' | FindInOption;
  invoiced?: boolean;
}

interface FindInOption {
  in?: string[];
  contains?: string;
  some?: string;
  id?: string;
  lte?: string;
  gte?: string;
}

interface StringFilter {
  equals?: string;
  in?: string | string[];
  notIn?: string | string[];
  lt?: string;
  lte?: string;
  gt?: string;
  gte?: string;
  contains?: string;
  startsWith?: string;
  endsWith?: string;
  mode?: 'default' | 'insensitive';
  not?: NestedStringFilter | string;
}

interface NestedStringFilter {
  equals?: string;
  in?: string | string[];
  notIn?: string | string[];
  lte?: string;
  gt?: string;
  gte?: string;
  contains?: string;
  startsWith?: string;
  endsWith?: string;
  not?: NestedStringFilter | string;
}

@Injectable({
  providedIn: 'root',
})
export class ActivitiesGateway extends BackendService<Activity> {
  protected readonly path = 'activities';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  public getActivities<T extends Activity>({
    options,
  }: {
    options?: ActivitiesOptions;
  }): Observable<ManyEntity<T>> {
    const params = stringify(options, {
      arrayFormat: 'indices',
    });
    return this.http.get<ManyEntity<T>>(
      `${this.config.host}/v2/${this.path}?${params}`,
    );
  }
}
