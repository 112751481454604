import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PartialActivityWhereInput } from '@wilson/api/gateway';
import { AuthState } from '@wilson/auth/core';
import {
  InvoiceActivityStatus,
  OperationStatus,
  ShiftState,
} from '@wilson/interfaces';
import { InvoiceDateTypeFilter, InvoicingDataStateModel } from '../..';

export interface ShiftUserLabels {
  shiftUserLabelIds?: string[];
}
export interface ShiftLabels {
  shiftLabelIds?: string[];
}

export interface ShiftProjects {
  shiftProjectIds?: string[];
}

export interface ShiftUserProfessions {
  shiftUserProfessionIds?: string[];
}

export interface Invoiced {
  invoiced?: InvoiceActivityStatus;
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceDataHelperService {
  constructor(private readonly store: Store) {}

  public ACTIVITY_RELATIONS = [
    'activityCategory',
    'agreement',
    'agreement.client',
    'service',
    'shift',
    'shift.shiftCategory',
    'shift.user',
    'shift.labels',
    'shift.user.labels',
    'shift.user.organizationalUnit',
  ];

  public JOB_RELATIONS = [
    'job',
    'job.activities',
    'job.activities.service',
    'job.activities.activityCategory',
    'job.activities.endLocation',
    'job.activities.startLocation',
    'job.activities.profession',
    'job.organizationalUnit',
    'organizationalUnit',
  ];

  getWhere(state: InvoicingDataStateModel): PartialActivityWhereInput {
    const where: PartialActivityWhereInput = {
      startDatetime: new Date(state.filters.dateRange.start).toISOString(),
      endDatetime: new Date(state.filters.dateRange.end).toISOString(),
      operationalStatus: OperationStatus.Completed,
      shift: {
        state: {
          in: [ShiftState.AcceptedTimes, ShiftState.SubmittedToPayrollProvider],
        },
        userId: {
          in: state.filters.employees,
        },
        shiftCategoryId: {
          in: state.filters.shiftCategories,
        },
        organizationalUnitId: {
          in:
            this.store
              .selectSnapshot(AuthState.accessibleOrgUnits)
              ?.map((orgUnit) => orgUnit.id)
              .filter((id): id is string => id !== undefined) || [],
        },
        user: {
          organizationalUnitId: { in: state.filters.orgUnit },
        },
      },
      job: {
        organizationalUnitId: { in: state.filters.partners },
      },
      jobId: { in: state.filters.jobId },
      activityCategoryId: { in: state.filters.activityCategories },
      agreementId: { in: state.filters.agreements },
      OR: state.filters.searchString
        ? [
            {
              name: {
                contains: state.filters.searchString,
                mode: 'insensitive',
              },
            },
            {
              shift: {
                name: {
                  contains: state.filters.searchString,
                  mode: 'insensitive',
                },
              },
            },
          ]
        : [],
    };

    if (state.filters.showServiceActivities) {
      where.serviceId = 'not-null';
    }

    if (
      where.shift &&
      state.filters.dateTypeFilterIndex === InvoiceDateTypeFilter.ByShift
    ) {
      where.shift.reportedStartDatetime = {
        gte: new Date(state.filters.dateRange.start).toISOString(),
        lte: new Date(state.filters.dateRange.end).toISOString(),
      };
    }

    return where;
  }

  getInvoicing(state: InvoicingDataStateModel): Invoiced {
    let invoicing = {};
    if (state.filters.invoiced) {
      invoicing = {
        invoiced: state.filters.invoiced === InvoiceActivityStatus.Invoiced,
      };
    }
    return invoicing;
  }

  getShiftLabelIds(state: InvoicingDataStateModel): ShiftLabels {
    let shiftLabels = {};
    if (state.filters.shiftLabelsIds) {
      shiftLabels = {
        shiftLabelIds: state.filters.shiftLabelsIds,
      };
    }
    return shiftLabels;
  }

  getShiftProjectIds(state: InvoicingDataStateModel): ShiftProjects {
    let shiftProjects = {};
    if (state.filters.shiftLabelsIds) {
      shiftProjects = {
        shiftProjectIds: state.filters.shiftProjectIds,
      };
    }
    return shiftProjects;
  }

  getShiftUserLabelIds(state: InvoicingDataStateModel): ShiftUserLabels {
    let shiftUserLabels = {};
    if (state.filters.shiftUserLabelIds) {
      shiftUserLabels = {
        shiftUserLabelIds: state.filters.shiftUserLabelIds,
      };
    }
    return shiftUserLabels;
  }

  getShiftUserProfessionIds(
    state: InvoicingDataStateModel,
  ): ShiftUserProfessions {
    let shiftUserProfessions = {};
    if (state.filters.profession) {
      shiftUserProfessions = {
        shiftUserProfessionIds: state.filters.profession,
      };
    }
    return shiftUserProfessions;
  }
}
