import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StayStatus } from '@wilson/interfaces';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'wilson-delete-stay-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, NzButtonModule],
  templateUrl: './delete-stay-button.component.html',
})
export class DeleteStayButtonComponent {
  @Input({
    required: true,
  })
  isOperationOnGoing = false;
  @Input({
    required: true,
  })
  stayStatus!: StayStatus | undefined;
  @Output() deleteStay = new EventEmitter<void>();

  constructor(
    private readonly translate: TranslateService,
    private readonly modalService: NzModalService,
  ) {}

  protected async performDelete() {
    if (this.stayStatus === StayStatus.Open) {
      return this.deleteStay.emit();
    } else {
      return this.modalService.confirm({
        nzTitle: this.translate.instant('page.stays.delete_stay'),
        nzContent: this.translate.instant('page.stays.delete_stay.description'),
        nzOkText: this.translate.instant('general.yes'),
        nzOkType: 'primary',
        nzOkDanger: true,
        nzCancelText: this.translate.instant('general.no'),
        nzOnOk: async () => {
          this.deleteStay.emit();
        },
      });
    }
  }
}
