/* eslint-disable @nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActivityServiceProfessionRendererComponent } from './activity-service-profession-renderer.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ProfessionCoreModule } from '@wilson/profession/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';

@NgModule({
  declarations: [ActivityServiceProfessionRendererComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule,
    ProfessionCoreModule,
    FeatureFlagsModule,
  ],
  exports: [ActivityServiceProfessionRendererComponent],
})
export class ActivityServiceProfessionRendererModule {}
