import { Injectable } from '@angular/core';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { ActivityCategoriesIdsMap } from '@wilson/interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DefaultActivityCategoryService {
  private readonly WilsonDefaultActivityCategoryId =
    'WilsonDefaultActivityCategoryId';
  private isFeatureFlagEnabled = false;

  constructor(private readonly featureFlagPurePipe: FeatureFlagPurePipe) {}

  public getDefaultActivityCategoryId(): string {
    if (this.isFeatureFlagEnabled) {
      const userSelectedCategory = localStorage.getItem(
        this.WilsonDefaultActivityCategoryId,
      );
      return userSelectedCategory || ActivityCategoriesIdsMap.TrainDrive;
    } else {
      return ActivityCategoriesIdsMap.TrainDrive;
    }
  }

  public setDefaultActivityCategoryId(id: string): void {
    try {
      localStorage.setItem(this.WilsonDefaultActivityCategoryId, id);
    } catch (error) {
      console.error('Failed to set default activity category:', error);
    }
  }

  public async setFeatureFlag() {
    this.isFeatureFlagEnabled = await firstValueFrom(
      this.featureFlagPurePipe.transform('portal-default-activity-categories'),
    );
  }
}
