import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, debounceTime, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Logout, RefreshAccessToken } from '../state/auth.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private requestToRefreshSubject = new Subject<boolean>();
  private accessTokenSubject = new Subject<string>();

  constructor(private readonly store: Store, private readonly router: Router) {
    this.requestToRefreshSubject
      .pipe(
        debounceTime(100),
        switchMap(() => this.dispatchRefresh()),
        switchMap(() =>
          this.store.select<string>((state) => state.auth.accessToken),
        ),
      )
      .subscribe((accessToken) => {
        this.accessTokenSubject.next(accessToken);
      });
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status !== 401) return throwError(error);
        if (req.headers.get('X-Ignore-Auth-Interceptor')) {
          return throwError(error);
        }

        return this.handle401Error(req, next);
      }),
    );
  }

  private addTokenToRequest(
    req: HttpRequest<unknown>,
    token: string,
  ): HttpRequest<unknown> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }

  private handle401Error(req: HttpRequest<unknown>, next: HttpHandler) {
    this.requestToRefreshSubject.next(true);
    return this.accessTokenSubject.asObservable().pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenToRequest(req, token))),
    );
  }

  private dispatchRefresh() {
    return this.store.dispatch(new RefreshAccessToken()).pipe(
      catchError(() => {
        console.warn('couldnt fetch new access token, logging out');
        return this.store
          .dispatch(new Logout())
          .pipe(finalize(() => this.router.navigate(['/auth'])));
      }),
    );
  }
}
