/* eslint-disable @nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AccountValueGetterService } from '@wilson/account/ag-grid';
import {
  HeaderRendererComponent,
  TitleRendererComponent,
} from '@wilson/ag-grid-shared';
import {
  defaultColDef,
  defaultGridOptionsFactory,
} from '@wilson/ag-grid/configuration';
import {
  NameRendererComponent,
  PrefScoreRendererComponent,
  SmartScoreRendererComponent,
  UserAvatarRenderer,
  ValidationRenderComponent,
  WilsonLearnRendererComponent,
} from '@wilson/ag-grid/renderers';
import { FeaturePurePipe } from '@wilson/authorization';
import { Score, ShiftAssignment, FeatureName } from '@wilson/interfaces';
import { OrganizationRendererComponent } from '@wilson/user-data/core';
import { ColDef, GridApi, RowClickedEvent } from 'ag-grid-community';
import { combineLatest, map, Observable } from 'rxjs';

@Component({
  selector: 'wilson-assign-staff-list',
  templateUrl: './assign-staff-list.component.html',
  styleUrls: ['./assign-staff-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignStaffListComponent implements OnChanges {
  @Input() shiftAssignment!: ShiftAssignment[];
  @Output() selectStaff = new EventEmitter();

  public isLoading = true;
  public selectedRow: number | null = null;
  private gridApi!: GridApi;

  FeatureName = FeatureName;

  public readonly defaultColDef = defaultColDef;
  public readonly gridOptions = {
    ...defaultGridOptionsFactory(),
    pagination: false,
    noRowsOverlayComponent: null,
  };

  frameworkComponents = {
    headerRenderer: HeaderRendererComponent,
    userAvatarRenderer: UserAvatarRenderer,
    titleRender: TitleRendererComponent,
    userNameRender: NameRendererComponent,
    orgUnitRenderer: OrganizationRendererComponent,
    validationRender: ValidationRenderComponent,
    prefScoreRender: PrefScoreRendererComponent,
    smartScoreRender: SmartScoreRendererComponent,
    wilsonLearnRenderer: WilsonLearnRendererComponent,
  };

  protected columnDefs$: Observable<ColDef[]> = combineLatest([
    this.featureFlagPipe.transform(FeatureName.Preference),
  ]).pipe(
    map(([isPreferenceFeatureEnabled]) => {
      return [
        {
          field: 'user',
          headerName: '',
          cellRenderer: 'userAvatarRenderer',
          width: 100,
          resizable: false,
          sortable: false,
        },
        {
          valueGetter: this.accountValueGetterService.userNameNestedValueGetter,
          headerName: this.translateService.instant('general.name'),
          cellRenderer: 'userNameRender',
        },
        {
          field: 'user.organizationalUnitId',
          cellRenderer: 'orgUnitRenderer',
          headerName: this.translateService.instant('service.orgunit'),
          width: 250,
        },
        {
          field: 'shiftValidationErrors',
          cellRenderer: 'validationRender',
          valueGetter: this.agGridHasQualification,
          headerName: this.translateService.instant('page.shift.requirements'),
          width: 250,
        },
        {
          field: 'suggestedByWilsonLearn',
          cellRenderer: 'wilsonLearnRenderer',
          headerName: '',
          width: 350,
          sortable: false,
        },
        {
          field: 'scores',
          cellRenderer: 'smartScoreRender',
          valueGetter: this.agGridHasSmartScore,
          headerName: this.translateService.instant(
            'shift.assignment.smartScore',
          ),
        },
        {
          field: 'scores',
          cellRenderer: 'prefScoreRender',
          valueGetter: this.agGridHasPrefScore,
          headerName: this.translateService.instant(
            'shift.assignment.prefScore',
          ),
          hide: !isPreferenceFeatureEnabled,
        },
      ];
    }),
  );

  constructor(
    public readonly accountValueGetterService: AccountValueGetterService,
    private translateService: TranslateService,
    private featureFlagPipe: FeaturePurePipe,
  ) {}

  ngOnChanges() {
    if (!this.shiftAssignment) this.isLoading = true;
    if (this.shiftAssignment?.length >= 0) this.isLoading = false;
  }

  onGridReady(params: { api: GridApi }) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit(undefined);
    this.gridApi.setDomLayout('autoHeight');
    const myGrid = document.querySelector('#myGrid') as {
      style?: { height: string };
    };
    if (myGrid && myGrid.style) {
      myGrid.style.height = '';
    }
  }

  selectAssignment(event: RowClickedEvent) {
    if (this.selectedRow != event.rowIndex) {
      this.selectedRow = event.rowIndex;
      this.selectStaff.emit(event.data);
    } else {
      this.selectedRow = null;
      this.selectStaff.emit(null);
    }
  }

  agGridHasSmartScore(params: { data: { scores: Score[] } }) {
    const scoreObj = params?.data?.scores.filter(
      (score) => score.name === 'shift-smart-score',
    );
    const score =
      scoreObj.length && scoreObj[0].score
        ? Number((scoreObj[0].score * 5).toFixed(1))
        : 0;
    return score;
  }

  agGridHasPrefScore(params: { data: { scores: Score[] } }) {
    const scoreObj = params?.data?.scores.filter(
      (score) => score.name === 'shift-preference-score',
    );
    const score =
      scoreObj.length && scoreObj[0].score
        ? Number(scoreObj[0].score.toFixed(1))
        : 0;
    return score;
  }

  agGridHasQualification(params: {
    data: { shiftValidationErrors: ValidationErrors[] };
  }) {
    return params?.data?.shiftValidationErrors.length === 0
      ? 'page.services.fulfilled'
      : 'page.services.partly_fulfilled';
  }
}
