import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivityNameParams, ShiftTemplate } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-template-activity-name-renderer',
  template: `
    <wilson-activity-category-and-name
      [activityName]="activityName"
      [activityCategory]="cellValue"
    >
    </wilson-activity-category-and-name>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateActivityNameRendererComponent
  implements ICellRendererAngularComp
{
  public cellValue!: string;
  public activityName!: string;

  async agInit(params: ICellRendererParams & ActivityNameParams) {
    this.cellValue = params.value;
    this.activityName = (params.data as ShiftTemplate).name;
  }

  refresh(params: ICellRendererParams & ActivityNameParams) {
    this.cellValue = params.value;
    this.activityName = params.data.name;

    return true;
  }
}
