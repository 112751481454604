import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wilson-activity-category-and-name',
  template: `
    <div>
      <div class="text-dark text-truncate">
        {{
          activityCategory
            ? ('activity_category.' + activityCategory | translate)
            : '---'
        }}
      </div>
      <div class="lh-m">
        <span *ngIf="activityName" class="list-header">{{ activityName }}</span>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCategoryAndNameComponent {
  @Input() activityName!: string;
  @Input() activityCategory!: string;
}
