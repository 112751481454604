<button
  nz-button
  nzType="text"
  nzDanger
  class="w-100 fw-bold"
  (click)="performDelete()"
  [disabled]="isOperationOnGoing"
  [nzLoading]="isOperationOnGoing"
>
  <span class="fas fa-trash-alt text-danger2 mx-2"></span>
  {{ 'page.stays.delete_stay' | translate }}
</button>
