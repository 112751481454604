/* eslint-disable @nx/enforce-module-boundaries */
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ActivitiesService } from '@wilson/activities';
import { ActivityTemplatesGateway } from '@wilson/api/gateway';
import { Activity, ActivityTemplate, Profession } from '@wilson/interfaces';
import { deepClone } from '@wilson/utils';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ChangeProfessionModalComponent } from '../change-profession-modal/change-profession-modal.component';

@Component({
  selector: 'wilson-profession-tag',
  templateUrl: './profession-tag.component.html',
  styleUrls: ['./profession-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfessionTagComponent {
  @Input() isEditable = false;
  @Input() activity: Activity | ActivityTemplate;
  @Input() isSeries = false;

  constructor(
    private readonly modalService: NgbModal,
    private readonly activitiesServices: ActivitiesService,
    private readonly activityTemplatesGateway: ActivityTemplatesGateway,
    private readonly translate: TranslateService,
    private readonly toastMessage: NzMessageService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  async openChangeProfessionModal() {
    const modalRef = this.modalService.open(ChangeProfessionModalComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.profession = this.activity.profession;
    try {
      const result: Profession = await modalRef.result;
      if (this.isSeries) {
        await this.saveProfessionToActivityTemplate(result);
      } else {
        await this.saveProfessionToActivity(result);
      }
      this.activity = deepClone({ ...this.activity, profession: result });
      this.toastMessage.success(this.translate.instant('popup.save.text'), {
        nzDuration: 3000,
      });
      this.cdRef.detectChanges();
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        this.toastMessage.error(this.translate.instant('general.error'), {
          nzDuration: 3000,
        });
      }
    }
  }

  async saveProfessionToActivity(profession: Profession) {
    await this.activitiesServices.updateActivity({
      id: this.activity.id,
      professionId: profession ? profession.id : null,
    });
  }

  async saveProfessionToActivityTemplate(profession: Profession) {
    await this.activityTemplatesGateway.updateActivityTemplate({
      id: this.activity.id,
      professionId: profession ? profession.id : null,
    });
  }
}
