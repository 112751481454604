import { getMenlo79GlobalConfig } from '@wilson/utils';

export const environment = {
  production: true,
  backend: {
    host: getMenlo79GlobalConfig('backend_host'),
  },
  sentry: {
    enabled: !!+getMenlo79GlobalConfig('sentry_enabled'),
    dsn: getMenlo79GlobalConfig('sentry_dsn_portal'),
    environment: getMenlo79GlobalConfig('sentry_environment'),
    tracingOrigins: getMenlo79GlobalConfig('sentry_tracing_origins').split(','),
  },
  application: 'portal',
  releaseVersion: '1.525.0',
  talkJsConfig: {
    talkjsAppId: getMenlo79GlobalConfig('talkjs_app_id'),
    role: getMenlo79GlobalConfig('talkjs_role'),
  },
  launchDarklyClientId: getMenlo79GlobalConfig('launchdarkly_client_id'),
  productFruitsWorkspaceCode: getMenlo79GlobalConfig(
    'product_fruits_workspace_code',
  ),
  matomo: {
    disabled: !!+getMenlo79GlobalConfig('matomo_disabled'),
    trackerUrl: getMenlo79GlobalConfig('matomo_tracker_url'),
    siteId: getMenlo79GlobalConfig('matomo_site_id'),
  },
  mapboxToken: getMenlo79GlobalConfig('mapbox_token'),
  csvBoxLicense: getMenlo79GlobalConfig('csv_box_license'),
  csvBoxOvertimeSheetLicense: getMenlo79GlobalConfig(
    'csv_box_overtime_sheet_license',
  ),
  csvBoxAccountSheetLicense: getMenlo79GlobalConfig(
    'csv_box_account_sheet_license',
  ),
  csvBoxQualificationSheetLicense: getMenlo79GlobalConfig(
    'csv_box_qualification_sheet_license',
  ),
  csvBoxRouteQualificationSheetLicense: getMenlo79GlobalConfig(
    'csv_box_route_qualification_sheet_license',
  ),
  csvBoxServicesSheetLicense: getMenlo79GlobalConfig(
    'csv_box_services_sheet_license',
  ),
};
