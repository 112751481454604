import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  Activity,
  ActivityCategory,
  ActivityTemplate,
  ResolvedActivityWithReports,
} from '@wilson/interfaces';
import { createFullActivityTemplateObject } from '@wilson/non-domain-specific/activities-helpers/services';
import { stringify } from 'qs';
import { Observable, firstValueFrom, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  private enabledCategories$ = this.getAllActivityCategories().pipe(
    map((categories) => {
      return categories.filter((category) => category.isEnabled);
    }),
    shareReplay(1),
  );

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
    private readonly translate: TranslateService,
  ) {}

  public getActivityCategory(id: string): Observable<ActivityCategory> {
    return this.httpClient.get<ActivityCategory>(
      `${this.config.host}/activity-categories/${id}`,
    );
  }

  public getEnabledActivityCategories(): Observable<ActivityCategory[]> {
    return this.enabledCategories$;
  }

  public getEnabledActivityCategoriesWithTranslationKey(): Observable<
    (ActivityCategory & { nameTranslationKey: string })[]
  > {
    return this.getEnabledActivityCategories().pipe(
      map((categories) => {
        const activityCategories = categories as (ActivityCategory & {
          nameTranslationKey: string;
        })[];

        activityCategories.map(
          (category) =>
            (category.nameTranslationKey =
              'activity_category.' + category.name),
        );
        activityCategories.sort((a, b) =>
          this.translate
            .instant(a.nameTranslationKey)
            .localeCompare(this.translate.instant(b.nameTranslationKey)),
        );
        return activityCategories;
      }),
    );
  }

  getAllActivityCategories(): Observable<ActivityCategory[]> {
    const params = stringify({
      relations: ['sectors', 'activityCategoryDefaultSetting'],
    });

    return this.httpClient.get<ActivityCategory[]>(
      `${this.config.host}/activity-categories?${params}`,
    );
  }

  updateActivityCategories(
    activities: {
      activityCategoryId: string;
      isEnabled: boolean;
    }[],
  ) {
    return this.httpClient.put(
      `${this.config.host}/activity-categories/organizational-unit`,
      {
        items: activities,
      },
    );
  }

  public getActivity(id: string): Observable<Activity> {
    return this.httpClient.get<Activity>(
      `${this.config.host}/activities/${id}`,
    );
  }

  public getResolvedActivity(
    id: string,
  ): Observable<ResolvedActivityWithReports[]> {
    return this.httpClient.post<ResolvedActivityWithReports[]>(
      `${this.config.host}/activities/resolved`,
      { id },
    );
  }

  public createActivities(activities: Activity[]): Observable<Activity[]> {
    return this.httpClient.post<Activity[]>(`${this.config.host}/activities`, {
      items: activities,
    });
  }

  public getActivitiesByShiftId(shiftId: string): Observable<Activity[]> {
    return this.httpClient.post<Activity[]>(
      `${this.config.host}/activities/search`,
      { shiftId },
    );
  }

  public updateActivity(activity: Partial<Activity>) {
    return firstValueFrom(
      this.httpClient.patch<Activity>(
        `${this.config.host}/activities/${activity.id}`,
        activity,
      ),
    );
  }

  public deleteActivity(activityId: string): Promise<Activity> {
    return firstValueFrom(
      this.httpClient.delete<Activity>(
        `${this.config.host}/activities/${activityId}`,
      ),
    );
  }

  public deleteActivityReports(activityId: string): Promise<Activity> {
    return firstValueFrom(
      this.httpClient.delete<Activity>(
        `${this.config.host}/activities/${activityId}/reports`,
      ),
    );
  }

  public createActivityTemplateFromActivity(
    activity: Activity,
  ): ActivityTemplate {
    return createFullActivityTemplateObject({
      activityCategoryId: activity.activityCategoryId,
      activityTemplateQualifications: null,
      endLocationId: activity.endLocationId,
      name: activity.name?.trim(),
      serviceSeriesId: null,
      shiftSeriesId: null,
      startLocationId: activity.startLocationId,
      viaLocationIds: activity.viaLocationIds,
      startDatetime: activity.startDatetime,
      endDatetime: activity.endDatetime,
      startTimeZone: 'Europe/Berlin', //Temporary hardcode until we implement dynamic timezone resolution
      endTimeZone: 'Europe/Berlin', //Temporary hardcode until we implement dynamic timezone resolution
    });
  }
}
