/* eslint-disable @nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AccountService } from '@wilson/account';
import {
  ActivityQualifications,
  ActivityTemplate,
  ActivityTemplateQualifications,
  Certificate,
  GeoLocation,
  Qualifiable,
  Qualifiables,
  QualifiablesWithCategory,
  QualificationCategoryName,
  ResolvedActivity,
  Route,
  RouteQualification,
  Sector,
  User,
  VehicleModel,
} from '@wilson/interfaces';
import { LocationsService } from '@wilson/locations';
import { RoutesService } from '@wilson/route';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { ActivityQualificationsService } from '../../../lib/activity-qualifications.service';
import { ActivityQualificationsModalDataService } from './services/activity-qualifications-modal-data.service';

@Component({
  selector: 'wilson-activity-qualifications-modal',
  templateUrl: './activity-qualifications-modal.component.html',
  styleUrls: ['./activity-qualifications-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityQualificationsModalComponent implements OnInit {
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() activity: ResolvedActivity;
  @Input() activityTemplate: ActivityTemplate;
  @Input() activityQualifications: ActivityQualifications[];

  existingRoute$: Observable<Route[]>;

  selectableQualifications$: Observable<QualifiablesWithCategory[]>;
  availableQualifiables$: Observable<Qualifiable[]>;

  resolvedUser$: Observable<User>;

  startLocation: GeoLocation;
  endLocation: GeoLocation;

  submitting = false;
  selectedQualifications: string[] = [];
  selectedQualifiables: Qualifiable[] = [];

  isLoading = true;

  private destroy$ = new Subject<undefined>();

  constructor(
    private readonly activityQualificationsService: ActivityQualificationsService,
    private readonly activityQualificationsModalDataService: ActivityQualificationsModalDataService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly activeModal: NgbActiveModal,
    private readonly routesService: RoutesService,
    private readonly accountService: AccountService,
    private readonly store: Store,
    private readonly locationsService: LocationsService,
  ) {}

  ngOnInit() {
    if (this.activityQualifications?.length) {
      this.selectedQualifications =
        this.activityQualificationsModalDataService.getQualificationIds(
          this.activityQualifications,
        );
    }
    this.existingRoute$ = this.activity
      ? this.routesService.searchRoute({
          viaLocationIds: this.activity.viaLocationIds
            ? this.activity.viaLocationIds
            : [],
          startLocationId: this.activity.startLocationId,
          endLocationId: this.activity.endLocationId,
        })
      : this.activityTemplate
      ? this.routesService.searchRoute({
          viaLocationIds: this.activityTemplate.viaLocationIds
            ? this.activityTemplate.viaLocationIds
            : [],
          startLocationId: this.activityTemplate.startLocationId,
          endLocationId: this.activityTemplate.endLocationId,
        })
      : of([]);

    this.selectableQualifications$ =
      this.activityQualificationsService.getAllSelectableQualifications();

    this.resolvedUser$ = this.accountService
      .getResolvedUser(this.store.selectSnapshot((state) => state.auth.userId))
      .pipe(map((users) => users[0]));
    const viaLocations$ = this.getGeoLocationsByIds();
    this.availableQualifiables$ = combineLatest([
      this.selectableQualifications$,
      this.existingRoute$,
      this.resolvedUser$,
      viaLocations$,
    ]).pipe(
      take(1),
      takeUntil(this.destroy$),
      map(([qualifiablesWithCategory, existingRoutes, user, viaLocations]) => {
        qualifiablesWithCategory.map((qualifiableWithCategory) => {
          qualifiableWithCategory = this.setRouteToQualifications(
            qualifiableWithCategory,
            this.activity || this.activityTemplate,
            existingRoutes,
            user.organizationalUnit.rootId
              ? user.organizationalUnit.rootId
              : user.organizationalUnit.id,
            viaLocations,
          );
          const locationsToQualifications = this.setLocationsToQualifications(
            qualifiableWithCategory,
            this.activity || this.activityTemplate,
          );

          const certificatesToQualifications = this.setQualifications(
            locationsToQualifications,
            QualificationCategoryName.Certificate,
          );

          qualifiableWithCategory = this.setQualifications(
            certificatesToQualifications,
            QualificationCategoryName.Vehicle,
          );

          return qualifiableWithCategory;
        });
        return this.prepareDisplayName(qualifiablesWithCategory);
      }),
      tap((qualifiable) => {
        this.setSelectedQualifiables(this.selectedQualifications, qualifiable);
        this.isLoading = false;
      }),
    );

    this.startLocation = this.activity
      ? this.activity.startLocation
      : this.activityTemplate
      ? this.activityTemplate.startLocation
      : null;
    this.endLocation = this.activity
      ? this.activity.endLocation
      : this.activityTemplate
      ? this.activityTemplate.endLocation
      : null;
  }

  cancel() {
    this.activeModal.close('cancel');
  }

  async saveQualification() {
    this.submitting = true;
    try {
      if (this.activity) {
        await this.activityQualificationsModalDataService.setActivityQualifications(
          this.selectedQualifiables,
          this.activity.id,
          this.activityQualifications,
        );
      } else if (this.activityTemplate) {
        await this.activityQualificationsModalDataService.setActivityTemplateQualifications(
          this.selectedQualifiables,
          this.activityTemplate,
          this
            .activityQualifications as unknown as ActivityTemplateQualifications[],
        );
      }
      this.toastr.success(
        this.translate.instant('component.activity_qualification.success'),
      );
      this.activeModal.close('saved');
    } catch {
      this.toastr.error(
        this.translate.instant('component.activity_qualification.error'),
      );
      this.activeModal.close('error');
    }
  }

  setLocationsToQualifications(
    qualifiablesWithCategory: QualifiablesWithCategory,
    activity: ResolvedActivity | ActivityTemplate,
  ) {
    if (
      qualifiablesWithCategory.qualificationCategory.name ===
      QualificationCategoryName.Location
    ) {
      (qualifiablesWithCategory.qualifications as GeoLocation[]).push(
        activity.startLocation,
      );
      if (activity.endLocation.id !== activity.startLocation.id) {
        (qualifiablesWithCategory.qualifications as GeoLocation[]).push(
          activity.endLocation,
        );
      }
    }
    return qualifiablesWithCategory;
  }

  setRouteToQualifications(
    qualifiablesWithCategory: QualifiablesWithCategory,
    activity: ResolvedActivity | ActivityTemplate,
    existingRoutes: Route[],
    organizationalUnitId: string,
    viaLocations: GeoLocation[],
  ) {
    if (
      qualifiablesWithCategory.qualificationCategory.name ===
      QualificationCategoryName.Route
    ) {
      const previouslySelectedQualificationsWithRoutes =
        this.activityQualifications.filter(
          (qualification) =>
            (qualification as unknown as RouteQualification).routeId,
        );
      const previouslySelectedRoutes =
        previouslySelectedQualificationsWithRoutes.map(
          (qualification) =>
            (qualification as unknown as RouteQualification).route,
        );
      if (previouslySelectedRoutes.length) {
        (qualifiablesWithCategory.qualifications as Route[]).push(
          ...previouslySelectedRoutes,
        );
      }
      if (existingRoutes.length) {
        (qualifiablesWithCategory.qualifications as Route[]).push(
          existingRoutes[0],
        );
      } else {
        let viaLocationNames = '';
        if (viaLocations.length) {
          viaLocations.forEach((location) => {
            viaLocationNames = viaLocationNames + location.locationCode + ' - ';
          });
        }
        const temporaryRoute: Route = {
          id: null,
          name: `${
            activity.startLocation?.locationCode ??
            activity.startLocation?.name ??
            '---'
          } - ${viaLocationNames}${
            activity.endLocation?.locationCode ??
            activity.endLocation?.name ??
            '---'
          }`,
          viaLocationIds: activity.viaLocationIds
            ? activity.viaLocationIds
            : [],
          startLocationId: activity.startLocationId,
          endLocationId: activity.endLocationId,
          organizationalUnitId,
        };

        (qualifiablesWithCategory.qualifications as Route[]).push(
          temporaryRoute,
        );
      }
    }
    return qualifiablesWithCategory;
  }

  prepareDisplayName(qualifiablesWithCategory: QualifiablesWithCategory[]) {
    let qualifiables: Qualifiable[] = [];
    qualifiablesWithCategory.map((qualifiable) => {
      qualifiable.qualifications.map((qualification) => {
        qualifiables.push({
          qualificationCategoryDisplayName:
            qualifiable.qualificationCategory.translatedName,
          qualificationDisplayName:
            this.activityQualificationsModalDataService.getQualificationName(
              qualifiable.qualificationCategory,
              qualification,
            ),
          qualificationCategory: qualifiable.qualificationCategory,
          qualification: qualification,
        });
      });
    });
    qualifiables = this.sortQualifiables(qualifiables);
    return qualifiables;
  }

  sortQualifiables(qualifiables: Qualifiable[]) {
    return qualifiables
      .sort((a, b) =>
        a.qualificationDisplayName.localeCompare(b.qualificationDisplayName),
      )
      .sort((a, b) =>
        a.qualificationCategoryDisplayName.localeCompare(
          b.qualificationCategoryDisplayName,
        ),
      );
  }

  public getGeoLocationsByIds() {
    const viaLocations$ = this.activity?.viaLocationIds
      ? this.locationsService
          .getGeoLocationsByIds(this.activity.viaLocationIds)
          .pipe(
            map((locations) => {
              locations.sort(
                (a, b) =>
                  this.activity.viaLocationIds.indexOf(a.id) -
                  this.activity.viaLocationIds.indexOf(b.id),
              );
              return locations;
            }),
          )
      : this.activityTemplate?.viaLocationIds
      ? this.locationsService
          .getGeoLocationsByIds(this.activityTemplate.viaLocationIds)
          .pipe(
            map((locations) => {
              locations.sort(
                (a, b) =>
                  this.activityTemplate.viaLocationIds.indexOf(a.id) -
                  this.activityTemplate.viaLocationIds.indexOf(b.id),
              );
              return locations;
            }),
          )
      : of([]);
    return viaLocations$;
  }

  public setSelectedQualifiables(
    qualificationIds: string[],
    availableQualifiables: Qualifiable[],
  ) {
    this.selectedQualifications = qualificationIds;
    this.selectedQualifiables = [];
    for (const id of qualificationIds) {
      const qualification = availableQualifiables.find(
        (q) => q.qualification.id === id,
      );
      if (qualification) {
        this.selectedQualifiables.push(qualification);
      }
    }
  }

  setQualifications(
    qualifiablesWithCategory: QualifiablesWithCategory,
    QualificationCategoryName: string,
  ) {
    if (
      qualifiablesWithCategory.qualificationCategory.name ===
      QualificationCategoryName
    ) {
      const sectors = this.store.selectSnapshot<Sector[]>(
        (state) => state.sector.sectors,
      );

      const sector = sectors[0];
      qualifiablesWithCategory.qualifications = sector?.id
        ? qualifiablesWithCategory.qualifications.filter(
            (qualifiable: Qualifiables) =>
              this.selectedQualifications.includes(qualifiable.id as string) ||
              (qualifiable as VehicleModel | Certificate)?.sectors?.some(
                (qualifiableSector) => qualifiableSector?.id === sector.id,
              ),
          )
        : qualifiablesWithCategory.qualifications;
    }
    return qualifiablesWithCategory;
  }
}
