import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  Activity,
  ActivityRow,
  OrganizationalUnit,
  PublicationStatus,
  Shift,
  User,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { TabEnum } from '@wilson/shift-timeline/services';
import { ShiftsDataService, ShiftsService } from '@wilson/shifts';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject, catchError, map, takeUntil, tap } from 'rxjs';
import {
  ShiftId,
  ShiftTimelineUnassignedRegionStateModel,
} from '../interfaces';
import { ActivitiesStateLoaderService } from '../services/activities-state-loader.service';
import { ShiftTimelineDataState } from '../shift-timeline-data/shift-timeline-data.state';
import {
  AddActivityToUnassignedRegion,
  AddShiftTemplateToSuggestionList,
  AddShiftWithoutActivitiesToUnassignedRegion,
  BulkAddShiftActivitiesToUnassignedRegion,
  BulkDeleteShiftActivitiesFromUnassignedRegion,
  ClearAllUnassignedActivitiesRelatedData,
  ClearShiftActivitiesRows,
  ClearUnassignedShiftsWithoutActivities,
  FetchActivitiesForUnassignedShifts,
  HideLoaderWhenLoadingUnassignedShiftsOrActivities,
  RemoveActivityFromUnassignedRegion,
  RemoveShiftWithoutActivitiesFromUnassignedRegion,
  SetSelectedUnassignedRegion,
  TimelineFetchUnassignedShiftsWithoutActivities,
  TimelineRemoveActivityFromUnassignedRegion,
  TimelineUpdateUnassignedActivities,
  TimelineUpdateUnassignedShiftsRegionWithoutActivities,
  TimelineUpsertActivityToUnassignedRegion,
  ToggleExpandUnassignedRegion,
  UpdateShiftWithoutActivitiesFromUnassignedRegion,
} from './shift-timeline-unassigned-region.actions';
import { TimelineFetchUnassignedActivitiesActionService } from './timeline-fetch-unassigned-activities-action.service';

const defaults: ShiftTimelineUnassignedRegionStateModel = {
  loadingActivitiesForShiftIds: [],
  selectedRegion: TabEnum.UnassignedShifts,
  shiftActivitiesRows: {},
  unassignedShiftsWithoutActivities: {},
  suggestedShiftTemplates: [],
  unassignedActivities: [],
  unassignedServices: [],
  unassignedJobs: [],
  isLoadingUnassignedShifts: false,
  unassignedActivitiesViewRows: [],
  hideLoaderWhenLoadingUnassignedShiftsOrActivities: false,
  isLoadingUnassignedActivities: false,
  isUnassignedRegionExpanded: false,
  isRefreshingUnassignedShifts: false,
  isRefreshingUnassignedActivities: false,
};

@State<ShiftTimelineUnassignedRegionStateModel>({
  name: 'shiftTimelineUnassignedRegion',
  defaults,
})
@Injectable()
export class ShiftTimelineUnassignedRegionState {
  private unsubRequestForUnassignedShiftsSubject = new Subject();
  private store = inject(Store);
  private toastrService = inject(ToastrService);
  private shiftsService = inject(ShiftsService);
  private translateService = inject(TranslateService);
  private shiftsDataService = inject(ShiftsDataService);
  private timelineFetchUnassignedActivitiesActionService = inject(
    TimelineFetchUnassignedActivitiesActionService,
  );

  constructor(
    private activitiesStateLoaderService: ActivitiesStateLoaderService,
  ) {}

  @Selector()
  static isRefreshingUnassignedShifts(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isRefreshingUnassignedShifts;
  }

  @Selector()
  static isRefreshingUnassignedActivities(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isRefreshingUnassignedActivities;
  }

  @Selector()
  static unassignedShiftsWithoutActivities(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.unassignedShiftsWithoutActivities;
  }

  @Selector()
  static suggestedShiftTemplates(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.suggestedShiftTemplates;
  }

  @Selector()
  static unassignedActivities(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.unassignedActivities;
  }

  @Selector()
  static unassignedActivitiesLength(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.unassignedActivities.length;
  }

  @Selector([
    ShiftTimelineUnassignedRegionState.unassignedShiftsWithoutActivities,
    ShiftTimelineDataState.visibleDateRange,
  ])
  static unassignedShiftsInView(
    shifts: Record<
      string,
      Omit<Shift, 'activities'> &
        WithPreparedAttributes & {
          id: string;
        }
    >,
    timeFrame: Interval,
  ) {
    return Object.values(shifts).filter((shift) => {
      const shiftStartDate = new Date(shift.startDatetime);
      const shiftEndDate = new Date(shift.endDatetime);
      return (
        (shiftStartDate >= timeFrame.start &&
          shiftStartDate <= timeFrame.end) ||
        (shiftEndDate >= timeFrame.start && shiftEndDate <= timeFrame.end)
      );
    });
  }

  @Selector()
  static isUnassignedRegionExpanded(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isUnassignedRegionExpanded;
  }

  @Selector()
  static isLoadingUnassignedShifts(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isLoadingUnassignedShifts;
  }

  @Selector()
  static hideLoaderWhenLoadingUnassignedShiftsOrActivities(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.hideLoaderWhenLoadingUnassignedShiftsOrActivities;
  }

  @Selector()
  static isLoadingUnassignedActivities(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.isLoadingUnassignedActivities;
  }

  @Selector()
  static unassignedActivitiesViewRows(
    state: ShiftTimelineUnassignedRegionStateModel,
  ) {
    return state.unassignedActivitiesViewRows;
  }

  @Selector()
  static unassignedServices(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.unassignedServices;
  }

  @Selector()
  static unassignedJobs(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.unassignedJobs;
  }

  @Selector()
  static shiftActivitiesRows(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.shiftActivitiesRows;
  }

  @Selector()
  static selectedRegion(state: ShiftTimelineUnassignedRegionStateModel) {
    return state.selectedRegion;
  }

  static shiftActivitiesRowsOf(shiftId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.shiftActivitiesRows],
      (rowsMap) => {
        return rowsMap[shiftId];
      },
    );
  }

  static unassignedShiftActivitiesArray(shiftId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.shiftActivitiesRowsOf(shiftId)],
      (activityRows: ActivityRow[]) => {
        return activityRows.flatMap((row) => Object.values(row));
      },
    );
  }

  static getService(serviceId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.unassignedServices],
      (unassignedServices) => {
        const service = unassignedServices.find(
          (service) => service.id === serviceId,
        );
        return service ? service : null;
      },
    );
  }

  static getServiceName(serviceId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.unassignedServices],
      (unassignedServices) => {
        const service = unassignedServices.find(
          (service) => service.id === serviceId,
        );
        return service ? service.name : '';
      },
    );
  }

  static getJobName(jobId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.unassignedJobs],
      (unassignedJobs) => {
        const job = unassignedJobs.find((job) => job.id === jobId);
        return job ? job.name : '';
      },
    );
  }

  static getShiftWithoutActivities(shiftId: string) {
    return createSelector(
      [ShiftTimelineUnassignedRegionState.unassignedShiftsWithoutActivities],
      (unassignedShifts) => {
        return Object.values(unassignedShifts).find(
          (shift) => shift.id === shiftId,
        );
      },
    );
  }

  @Action(ClearShiftActivitiesRows)
  clearDeterminedShiftActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
  ) {
    ctx.patchState({
      shiftActivitiesRows: {},
    });
  }

  @Action(ClearUnassignedShiftsWithoutActivities)
  clearUnassignedShiftsWithoutActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
  ) {
    ctx.patchState({
      unassignedShiftsWithoutActivities: {},
    });
  }

  @Action(ClearAllUnassignedActivitiesRelatedData)
  clearAllUnassignedActivitiesRelatedData(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
  ) {
    ctx.patchState({
      unassignedActivities: [],
      unassignedJobs: [],
      unassignedServices: [],
      unassignedActivitiesViewRows: [],
    });
  }

  @Action(SetSelectedUnassignedRegion)
  setUnassignedRegion(
    { patchState }: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    { payload }: SetSelectedUnassignedRegion,
  ) {
    patchState({
      selectedRegion: payload,
    });
  }

  @Action(ToggleExpandUnassignedRegion)
  toggleExpandUnassignedRegion(
    { patchState }: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    { payload }: ToggleExpandUnassignedRegion,
  ) {
    const updatePayload: Pick<
      ShiftTimelineUnassignedRegionStateModel,
      'isUnassignedRegionExpanded'
    > = {
      isUnassignedRegionExpanded: payload,
    };
    patchState(updatePayload);
  }

  @Action(HideLoaderWhenLoadingUnassignedShiftsOrActivities)
  hideLoaderWhenLoadingUnassignedShiftsOrActivities(
    { patchState }: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    { payload }: HideLoaderWhenLoadingUnassignedShiftsOrActivities,
  ) {
    const updatePayload: Pick<
      ShiftTimelineUnassignedRegionStateModel,
      'hideLoaderWhenLoadingUnassignedShiftsOrActivities'
    > = {
      hideLoaderWhenLoadingUnassignedShiftsOrActivities: payload,
    };
    patchState(updatePayload);
  }

  @Action(TimelineFetchUnassignedShiftsWithoutActivities)
  fetchUnassignedShiftsWithoutActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
  ) {
    const timeFrame = this.store.selectSnapshot(
      ShiftTimelineDataState.timeframe,
    );

    ctx.patchState({
      isLoadingUnassignedShifts: true,
      isUnassignedRegionExpanded: false,
      hideLoaderWhenLoadingUnassignedShiftsOrActivities: false,
    });

    return this.fetchUnassignedShiftsAndActivities(timeFrame, ctx).pipe(
      tap((transformedShifts) => {
        ctx.patchState({
          unassignedShiftsWithoutActivities: transformedShifts,
          isLoadingUnassignedShifts: false,
          hideLoaderWhenLoadingUnassignedShiftsOrActivities: false,
        });
      }),
      catchError(() => {
        this.toastrService.error(
          this.translateService.instant('general.something_went_wrong'),
        );
        return [];
      }),
    );
  }

  @Action(TimelineUpdateUnassignedShiftsRegionWithoutActivities)
  updateUnassignedActivitiesWithoutActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpdateUnassignedShiftsRegionWithoutActivities,
  ) {
    ctx.patchState({
      isRefreshingUnassignedShifts: true,
    });
    return this.fetchUnassignedShiftsAndActivities(action.timeFrame, ctx).pipe(
      tap((transformedShifts) => {
        ctx.setState(
          patch({
            unassignedShiftsWithoutActivities: patch(transformedShifts),
            isLoadingUnassignedShifts: false,
            isRefreshingUnassignedShifts: false,
          }),
        );
      }),
      catchError(() => {
        this.toastrService.error(
          this.translateService.instant('general.something_went_wrong'),
        );
        return [];
      }),
    );
  }

  @Action(TimelineUpdateUnassignedActivities)
  timelineUpdateUnassignedActivities(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpdateUnassignedActivities,
  ) {
    return this.timelineFetchUnassignedActivitiesActionService.handleUpdate(
      ctx,
      action,
    );
  }

  @Action(UpdateShiftWithoutActivitiesFromUnassignedRegion)
  updateShiftWithoutActivitiesFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: UpdateShiftWithoutActivitiesFromUnassignedRegion,
  ) {
    ctx.setState(
      patch({
        unassignedShiftsWithoutActivities: patch({
          [action.shift.id as string]: action.shift,
        }),
      }),
    );
  }

  @Action(RemoveShiftWithoutActivitiesFromUnassignedRegion)
  removeShiftWithoutActivitiesFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: RemoveShiftWithoutActivitiesFromUnassignedRegion,
  ) {
    const state = ctx.getState();
    const shifts = {
      ...state.unassignedShiftsWithoutActivities,
    };
    delete shifts[action.payload.shiftId];

    ctx.patchState({
      unassignedShiftsWithoutActivities: shifts,
    });
  }

  @Action(BulkDeleteShiftActivitiesFromUnassignedRegion)
  bulkDeleteShiftActivitiesToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: BulkDeleteShiftActivitiesFromUnassignedRegion,
  ) {
    const state = ctx.getState();
    const shiftActivitiesRows = cloneDeep(state.shiftActivitiesRows);
    const activityRows = shiftActivitiesRows[action.shiftId];
    if (activityRows) {
      delete shiftActivitiesRows[action.shiftId];
    }
    ctx.setState(
      patch({
        shiftActivitiesRows: shiftActivitiesRows,
      }),
    );
  }

  @Action(BulkAddShiftActivitiesToUnassignedRegion)
  addShiftActivitiesToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: BulkAddShiftActivitiesToUnassignedRegion,
  ) {
    ctx.setState(
      patch({
        shiftActivitiesRows: patch({
          [action.shiftId]: action.activityRow,
        }),
      }),
    );
  }

  @Action(AddShiftWithoutActivitiesToUnassignedRegion)
  addShiftWithoutActivitiesToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: AddShiftWithoutActivitiesToUnassignedRegion,
  ) {
    ctx.setState(
      patch({
        unassignedShiftsWithoutActivities: patch({
          [action.shift.id]: {
            ...action.shift,
            userId: null,
            publicationStatus: PublicationStatus.NotPublished,
          },
        }),
      }),
    );
  }

  @Action(TimelineUpsertActivityToUnassignedRegion)
  timelineUpsertActivityToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineUpsertActivityToUnassignedRegion,
  ) {
    const state = ctx.getState();
    let activityRows = state.shiftActivitiesRows[action.shiftId];

    if (!activityRows) {
      activityRows = [];
    }
    const allActivities = activityRows.reduce((acc, row) => {
      return { ...acc, ...row };
    }, {});

    if (action.activity.id) {
      allActivities[action.activity.id] = action.activity;
    }

    const determinedActivities =
      this.activitiesStateLoaderService.getActivityRows(
        Object.values(allActivities) as (Activity & { id: string })[],
      );

    ctx.setState(
      patch({
        shiftActivitiesRows: patch({
          [action.shiftId]: determinedActivities,
        }),
      }),
    );
  }

  @Action(TimelineRemoveActivityFromUnassignedRegion)
  timelineRemoveActivityFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: TimelineRemoveActivityFromUnassignedRegion,
  ) {
    const state = ctx.getState();
    let activityRows = state.shiftActivitiesRows[action.shiftId];

    if (!activityRows) {
      activityRows = [];
    }
    const allActivities = activityRows.reduce((acc, row) => {
      return { ...acc, ...row };
    }, {});

    delete allActivities[action.activityId];

    const determinedActivities =
      this.activitiesStateLoaderService.getActivityRows(
        Object.values(allActivities) as (Activity & { id: string })[],
      );

    ctx.setState(
      patch({
        shiftActivitiesRows: patch({
          [action.shiftId]: determinedActivities,
        }),
      }),
    );
  }

  private fetchUnassignedShiftsAndActivities(
    timeFrame: Interval,
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
  ) {
    this.unsubRequestForUnassignedShiftsSubject.next(null);
    return this.shiftsService
      .getShifts<
        Omit<Shift, 'activities'> &
          WithPreparedAttributes & {
            user: User;
            organizationalUnit: OrganizationalUnit;
          } & { id: string }
      >({
        where: {
          startDate: [
            new Date(timeFrame.start).toISOString(),
            new Date(timeFrame.end).toISOString(),
          ],
          userId: 'null',
        },
        relations: [
          'organizationalUnit',
          'user',
          'shiftCategory',
          'shiftSeries',
          'labels',
          'startLocation',
          'endLocation',
        ],
        limit: 0,
        offset: 0,
        order: {},
      })
      .pipe(
        takeUntil(this.unsubRequestForUnassignedShiftsSubject),
        map((response) => {
          const transformedShifts = response.data.reduce(
            (record, shift) => {
              record[shift.id] = shift;
              return record;
            },
            { ...ctx.getState().unassignedShiftsWithoutActivities } as Record<
              ShiftId,
              Omit<Shift, 'activities'> &
                WithPreparedAttributes & {
                  user: User;
                  organizationalUnit: OrganizationalUnit;
                } & { id: string }
            >,
          );

          return transformedShifts;
        }),
      );
  }

  @Action(AddShiftTemplateToSuggestionList)
  addShiftTemplateToSuggestionList(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: AddShiftTemplateToSuggestionList,
  ) {
    const state = ctx.getState();
    const suggestedShiftTemplates = cloneDeep(state.suggestedShiftTemplates);
    if (
      suggestedShiftTemplates.findIndex(
        (template) => template.id === action.shiftTemplate.id,
      ) === -1
    ) {
      if (suggestedShiftTemplates.length === 3) {
        suggestedShiftTemplates.shift();
      }
      suggestedShiftTemplates.push(action.shiftTemplate);
    }
    ctx.patchState({
      suggestedShiftTemplates,
    });
  }

  @Action(AddActivityToUnassignedRegion)
  async addActivityToUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: AddActivityToUnassignedRegion,
  ) {
    const state = ctx.getState();
    const activities = cloneDeep(state.unassignedActivities);
    activities.push(action.activity);
    const updatablePartialState =
      await this.timelineFetchUnassignedActivitiesActionService.getUpdatablePartialStateSync(
        activities,
      );
    ctx.patchState({
      ...updatablePartialState,
      unassignedActivities: activities,
    });
  }

  @Action(RemoveActivityFromUnassignedRegion)
  async removeActivityFromUnassignedRegion(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    action: RemoveActivityFromUnassignedRegion,
  ) {
    const state = ctx.getState();
    const activities = cloneDeep(state.unassignedActivities);
    const activityToRemoveIndex = activities.findIndex(
      (shift) => shift.id === action.activity.id,
    );
    activities.splice(activityToRemoveIndex, 1);

    const updatablePartialState =
      await this.timelineFetchUnassignedActivitiesActionService.getUpdatablePartialStateSync(
        activities,
      );
    ctx.patchState({
      ...updatablePartialState,
      unassignedActivities: activities,
    });
  }

  @Action(FetchActivitiesForUnassignedShifts)
  fetchActivitiesForUnassignedShifts(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    { shiftIds }: FetchActivitiesForUnassignedShifts,
  ) {
    this.activitiesStateLoaderService.loadActivitiesToUnassignedRegionState(
      ctx,
      shiftIds,
    );
  }
}
