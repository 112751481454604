import { Injectable, NgZone } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage-angular';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { AccountService, UsersCacheService } from '@wilson/account';
import {
  ActivityCategoryContract,
  Address,
  Contract,
  HolidayRegionCode,
  JwtToken,
  LoginResponse,
  MobileSetting,
  OrganizationalUnit,
  User,
  UserRole,
  UserSetting,
} from '@wilson/interfaces';
import { ShiftTimelineKPISettingsState } from '@wilson/kpi-settings/state';
import { SideNavSettingsState } from '@wilson/side-nav-settings/state';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Subscription, firstValueFrom, of, timer } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { AuthService } from '../lib/auth.service';
import {
  InitAuthState,
  InitializeAzureSSOFlow,
  InitializeUserWithRootOrgUnit,
  Login,
  Logout,
  RefreshAccessToken,
  SetCurrentlyReplacingAzureTokens,
  SetLoggingOutUser,
  UpdateMobileSettings,
  UpdateUser,
} from './auth.actions';

export interface AuthStateModel {
  userId: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
  initializingUser: boolean;
  loggingOutUser: boolean;
  currentlyReplacingAzureTokens: boolean;
}

export const AUTH_STATE_NAME = 'auth';
export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>(AUTH_STATE_NAME);
const ACCESS_TOKEN_TIMEOUT_FACTOR = 0.75;

@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: {
    userId: null,
    accessToken: null,
    refreshToken: null,
    user: null,
    initializingUser: false,
    loggingOutUser: false,
    currentlyReplacingAzureTokens: false,
  },
})
@Injectable()
export class AuthState {
  private accessTokenTimeoutSubscription: Subscription | undefined = undefined;

  constructor(
    private readonly authService: AuthService,
    private readonly jwtHelperService: JwtHelperService,
    private readonly accountService: AccountService,
    private readonly ngZone: NgZone,
    private readonly storage: Storage,
    private readonly usersCacheService: UsersCacheService,
  ) {}

  @Selector()
  static isUserLoggedOut(state: AuthStateModel) {
    return !state.userId;
  }

  @Selector()
  static address(state: AuthStateModel): Address | null | undefined {
    return state.user?.address;
  }

  @Selector()
  static userId(state: AuthStateModel) {
    return state.userId;
  }

  @Selector()
  static user(state: AuthStateModel): User | null | undefined {
    return state?.user;
  }

  @Selector()
  static contract(state: AuthStateModel): Contract | null | undefined {
    return state.user?.contract;
  }

  @Selector()
  static activityCategoryContracts(
    state: AuthStateModel,
  ): ActivityCategoryContract[] | undefined {
    return state.user?.contract?.activityCategoryContracts;
  }

  @Selector()
  static userRoles(state: AuthStateModel): UserRole[] | undefined {
    return state.user?.userRoles;
  }

  @Selector()
  static rootOrgUnit(state: AuthStateModel): OrganizationalUnit | undefined {
    return (
      state.user?.organizationalUnit?.root ?? state.user?.organizationalUnit
    );
  }

  @Selector()
  static invoiceNumberPrefix(state: AuthStateModel): string | undefined {
    return (
      state.user?.organizationalUnit?.root?.invoiceNumberCustomPrefix ??
      state.user?.organizationalUnit?.invoiceNumberCustomPrefix
    );
  }

  @Selector()
  static rootOrgUnitId(state: AuthStateModel): string | undefined {
    return AuthState.rootOrgUnit(state)?.id;
  }

  @Selector()
  static rootOrgUnitName(state: AuthStateModel): string | undefined {
    return AuthState.rootOrgUnit(state)?.name;
  }

  @Selector()
  static mobileSettings(state: AuthStateModel): MobileSetting | undefined {
    return (AuthState.rootOrgUnit(state)?.mobileSettings ?? [])[0];
  }

  @Selector()
  static userSettings(state: AuthStateModel): UserSetting | undefined {
    return AuthState.user(state)?.userSetting;
  }

  @Selector()
  static initializingUser(state: AuthStateModel): boolean {
    return state.initializingUser;
  }

  @Selector()
  static accessToken(state: AuthStateModel): string | null {
    return state.accessToken;
  }

  @Selector()
  static accessibleOrgUnits(
    state: AuthStateModel,
  ): OrganizationalUnit[] | undefined {
    return state.user?.accessibleOrganizationalUnits;
  }

  @Selector()
  static organizationalUnit(
    state: AuthStateModel,
  ): OrganizationalUnit | undefined {
    return state.user?.organizationalUnit;
  }

  @Selector()
  static publicHolidaysRegion(
    state: AuthStateModel,
  ): HolidayRegionCode | undefined {
    return state.user?.organizationalUnit?.publicHolidaysRegion;
  }

  @Selector()
  static organizationalUnitId(state: AuthStateModel): string | undefined {
    return state.user?.organizationalUnitId;
  }

  @Action(InitAuthState)
  async initAuthState(ctx: StateContext<AuthStateModel>): Promise<void> {
    const auth = await this.storage.get(AUTH_STATE_NAME);
    if (!auth) return;

    ctx.patchState(auth);

    this.setupNewAccessTokenRefreshTimer(ctx);
    this.identifyUserForSentry(ctx);

    return Promise.resolve();
  }

  private identifyUserForSentry(ctx: StateContext<AuthStateModel>): void {
    if (!ctx.getState().userId) return;
    Sentry.setUser({ id: ctx.getState().userId ?? undefined });
  }

  private setupNewAccessTokenRefreshTimer(ctx: StateContext<AuthStateModel>) {
    this.clearAccessTokenTimer();
    if (!ctx.getState().accessToken) return;
    const timeoutMilliseconds = this.calcTimeout(ctx);

    this.ngZone.runOutsideAngular(() => {
      this.accessTokenTimeoutSubscription = timer(
        timeoutMilliseconds,
      ).subscribe(() => {
        this.ngZone.run(() => {
          firstValueFrom(this.refreshAccessToken(ctx));
        });
      });
    });
  }

  private clearAccessTokenTimer(): void {
    if (this.accessTokenTimeoutSubscription) {
      this.accessTokenTimeoutSubscription.unsubscribe();
    }
  }

  private calcTimeout(ctx: StateContext<AuthStateModel>) {
    const accessToken = ctx.getState().accessToken;

    if (accessToken) {
      let { iat: issuedAt, exp: expiresAt } =
        this.jwtHelperService.decodeToken(accessToken);
      issuedAt *= 1000;
      expiresAt *= 1000;
      const delta =
        (expiresAt - issuedAt) * ACCESS_TOKEN_TIMEOUT_FACTOR -
        (Date.now() - issuedAt);
      return Math.max(0, delta);
    } else {
      return 0;
    }
  }

  @Action(UpdateMobileSettings)
  updateMobileSettings(
    ctx: StateContext<AuthStateModel>,
    action: UpdateMobileSettings,
  ) {
    const state = ctx.getState();

    if (state.user?.organizationalUnit) {
      const updatedUser: User = {
        ...state.user,
        organizationalUnit: {
          ...state.user.organizationalUnit,
          mobileSettings: [action.mobileSettings],
        },
      };

      ctx.patchState({
        user: updatedUser,
      });
    }
  }

  @Action(InitializeUserWithRootOrgUnit)
  initializeUserWithRootOrgUnit(ctx: StateContext<AuthStateModel>) {
    if (!ctx.getState().userId) return of(null);
    ctx.patchState({
      initializingUser: true,
    });

    return this.accountService
      .get(ctx.getState().userId ?? undefined, {
        relations: [
          'accessibleOrganizationalUnits',
          'contract',
          'contract.activityCategoryContracts',
          'organizationalUnit',
          'organizationalUnit.mobileSettings',
          'organizationalUnit.root',
          'organizationalUnit.root.mobileSettings',
          'userRoles',
          'userSetting',
        ],
      })
      .pipe(
        tap((user) => {
          ctx.patchState({
            user,
            initializingUser: false,
          });
          this.storage.set(AUTH_STATE_NAME, ctx.getState());
        }),
      );
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.authService.login(action.email, action.password).pipe(
      take(1),
      tap((response: LoginResponse) => {
        const decodedToken = this.jwtHelperService.decodeToken<JwtToken>(
          response.accessToken,
        );

        if (decodedToken) {
          ctx.patchState({
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            userId: decodedToken.sub,
          });
          this.setupNewAccessTokenRefreshTimer(ctx);
          this.storage.set(AUTH_STATE_NAME, ctx.getState());
        } else {
          console.error('Unable to decode token', response.accessToken);
        }
      }),
    );
  }

  @Action(Logout)
  async logout(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    Sentry.setUser(null);
    if (!state.refreshToken) {
      return of(null);
    }

    const statesToKeep = [ShiftTimelineKPISettingsState, SideNavSettingsState];
    ctx.dispatch(new StateResetAll(...statesToKeep));

    ctx.patchState({
      accessToken: null,
      refreshToken: null,
      userId: null,
      user: null,
    });
    await this.storage.clear();
    this.usersCacheService.clearUserCache();
    return this.authService.logout(state.refreshToken).pipe(
      catchError(() => {
        console.warn(
          'logout failed because refreshToken was rejected, state is cleared',
        );
        return of(null);
      }),
    );
  }

  @Action(SetLoggingOutUser)
  async setLoggingOutUser(
    ctx: StateContext<AuthStateModel>,
    { loggingOutUser }: SetLoggingOutUser,
  ) {
    ctx.patchState({
      loggingOutUser: loggingOutUser,
    });
  }

  @Action(RefreshAccessToken)
  refreshAccessToken(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    if (!state.refreshToken) {
      return of(null);
    }

    return this.authService.refreshToken(state.refreshToken).pipe(
      take(1),
      tap((accessToken) => {
        ctx.patchState({
          accessToken,
        });
        this.setupNewAccessTokenRefreshTimer(ctx);
        this.storage.set(AUTH_STATE_NAME, ctx.getState());
      }),
    );
  }

  @Action(UpdateUser)
  updateUser(ctx: StateContext<AuthStateModel>, action: UpdateUser) {
    if (!ctx.getState().userId) return of(null);

    ctx.patchState({
      user: action.user,
    });
    this.storage.set(AUTH_STATE_NAME, ctx.getState());

    return this.accountService.updateUser(action.user);
  }

  @Action(InitializeAzureSSOFlow)
  async initializeAzureSSOFlow(
    ctx: StateContext<AuthStateModel>,
    { accessToken, refreshToken }: InitializeAzureSSOFlow,
  ) {
    const decodedToken =
      this.jwtHelperService.decodeToken<JwtToken>(accessToken);

    if (decodedToken) {
      ctx.patchState({
        accessToken: accessToken,
        refreshToken: refreshToken,
        userId: decodedToken.sub,
        currentlyReplacingAzureTokens: false,
      });
      this.setupNewAccessTokenRefreshTimer(ctx);
      this.storage.set(AUTH_STATE_NAME, ctx.getState());
    } else {
      console.error('Unable to decode token', accessToken);
    }
  }

  @Action(SetCurrentlyReplacingAzureTokens)
  async setCurrentlyReplacingAzureTokens(
    ctx: StateContext<AuthStateModel>,
    { currentlyReplacingAzureTokens }: SetCurrentlyReplacingAzureTokens,
  ) {
    ctx.patchState({
      currentlyReplacingAzureTokens: currentlyReplacingAzureTokens,
    });
  }
}
