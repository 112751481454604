import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { FilesService } from '@wilson/files';
import {
  FileAccess,
  PublicationStatus,
  QualificationName,
  ResolvedActivity,
  ShiftQuickCheckValidate,
  ShiftValidationDetails,
  User,
} from '@wilson/interfaces';
import { ShiftValidationsGateway } from '@wilson/shift-validations/core';
import { ShiftValidationErrorService, ShiftsService } from '@wilson/shifts';
import { NzTooltipBaseDirective } from 'ng-zorro-antd/tooltip';
import { Observable, firstValueFrom } from 'rxjs';

@Component({
  selector: 'wilson-user-assignment',
  templateUrl: './user-assignment.component.html',
  styleUrls: ['./user-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAssignmentComponent implements OnInit {
  @ViewChild('tooltip') tooltip: NzTooltipBaseDirective;

  @Input() activity: ResolvedActivity;
  @Input() showValidations = true;
  @Input() showJobLink = true;
  @Input() isProvider = false;

  @Output() searchStaff = new EventEmitter();

  public faInValidIcon = faTimesCircle;
  public userProfileImage: SafeResourceUrl;
  public loading = true;
  public assignedUser: Partial<User>;
  public isAssignedToShift = false;
  public isAssignedToProviderShift = false;
  public shiftName: string;
  public disableAssignment = false;
  public userHover = false;
  public noUserHover = false;
  public shiftAssignedUrl: string;
  public workingTimeValidations: ShiftValidationDetails[] = [];
  public workingTimeValidation$: Observable<ShiftValidationDetails[]>;
  public shiftLogicValidations: ShiftValidationDetails[] = [];
  public qualificationNames: QualificationName[] = [];
  public isShiftValid = true;
  public professionValidations!: ShiftValidationDetails[];

  constructor(
    private readonly shiftService: ShiftsService,
    private readonly shiftValidationsGateway: ShiftValidationsGateway,
    private readonly shiftValidationErrorService: ShiftValidationErrorService,
    private readonly cd: ChangeDetectorRef,
    private readonly filesService: FilesService,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  async ngOnInit() {
    let user: Partial<User>;
    if (this.activity?.shiftId) {
      this.isAssignedToShift = true;
      this.isAssignedToProviderShift =
        !!this.activity.jobId && !!this.activity.shiftId;

      if (this.activity?.shift?.user) {
        user = this.activity.shift.user;
        this.shiftName = this.activity.shift.name ?? null;
        this.disableAssignment =
          this.activity.shift.publicationStatus !==
          PublicationStatus.NotPublished;
      } else {
        const shift = await firstValueFrom(
          this.shiftService.getResolvedShift(this.activity.shiftId),
        );
        this.shiftName = shift[0]?.name ?? null;
        this.disableAssignment =
          shift[0]?.publicationStatus !== PublicationStatus.NotPublished;
        if (this.activity?.jobId) {
          user = await firstValueFrom(
            this.shiftService.getShiftUser(this.activity.shiftId),
          );
        } else {
          user = shift[0]?.user;
        }
      }

      if (user) {
        this.assignedUser = user;

        if (this.assignedUser.profileImageUrl) {
          try {
            this.assignedUser.profileImageUrl = await this.getProfileImage(
              this.assignedUser.profileImageUrl,
            );
          } catch (error) {
            this.assignedUser.profileImageUrl =
              'assets/img/service/user-assigned.svg';
          }
        } else {
          this.assignedUser.profileImageUrl =
            'assets/img/service/user-assigned.svg';
        }
        if (this.showValidations) {
          await this.getShiftValidations();
        }
      } else {
        this.shiftAssignedUrl = 'assets/img/service/shift-assigned.svg';
      }
    }
    this.loading = false;
    this.cd.detectChanges();
  }

  async getShiftValidations() {
    const isUserApprovalCheckFeatureEnabled = await firstValueFrom(
      this.featureFlagPurePipe.transform(
        'portal-shift-validation-user-approval',
      ),
    );

    const isProfessionCheckEnabled = await firstValueFrom(
      this.featureFlagPurePipe.transform(
        'portal-shift-validation-user-professions',
      ),
    );

    const [shiftValidations] = await firstValueFrom(
      this.shiftValidationsGateway.getShiftValidationWithContractId(
        this.activity.shiftId as string,
        this.activity?.job?.contractId as string,
      ),
    );

    const shiftValidationsFiltered =
      isUserApprovalCheckFeatureEnabled && isProfessionCheckEnabled
        ? shiftValidations
        : this.filterShiftValidation(
            shiftValidations,
            isUserApprovalCheckFeatureEnabled,
            isProfessionCheckEnabled,
          );

    this.workingTimeValidations =
      ShiftValidationErrorService.getShiftWorkingTimeValidation(
        shiftValidationsFiltered.validations,
        isUserApprovalCheckFeatureEnabled,
      );

    this.shiftLogicValidations =
      this.shiftValidationErrorService.getShiftLogicValidation(
        shiftValidationsFiltered.validations,
      );

    this.qualificationNames =
      this.shiftValidationErrorService.getQualificationName(
        shiftValidationsFiltered.validations,
      );

    this.professionValidations =
      this.shiftValidationErrorService.getShiftProfessionValidation(
        shiftValidationsFiltered.validations,
        isProfessionCheckEnabled,
      );

    this.isShiftValid =
      this.workingTimeValidations?.every((item) => item.valid) &&
      this.shiftLogicValidations?.every((item) => item.valid) &&
      this.qualificationNames?.every((item) => item.valid);
    this.professionValidations?.every((item) => item.valid);
  }

  private filterShiftValidation(
    shiftValidations,
    isUserApprovalCheckEnabled,
    isProfessionCheckEnabled,
  ) {
    const { validations } = shiftValidations;
    let filteredValidations = [];
    if (!isUserApprovalCheckEnabled) {
      filteredValidations = validations.filter(
        (validation) =>
          validation.checkName !== ShiftQuickCheckValidate.UserApprovalCheck,
      );
    } else if (!isProfessionCheckEnabled) {
      filteredValidations = validations.filter(
        (validation) =>
          validation.checkName !== ShiftQuickCheckValidate.ShiftProfessionCheck,
      );
    }

    return { ...shiftValidations, validations: filteredValidations };
  }

  onTooltipExpanded() {
    this.tooltip.updatePosition();
  }

  async getProfileImage(url: string) {
    if (this.isS3Url(url)) {
      const profile: FileAccess | undefined = await firstValueFrom(
        this.filesService.getFileAccess(url),
      );
      return profile?.accessUrl || 'assets/img/service/user-assigned.svg';
    } else {
      return url;
    }
  }

  searchForStaffOnActivity(activity: ResolvedActivity) {
    this.searchStaff.emit(activity);
  }

  openInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  private isS3Url(url: string): boolean {
    const s3UrlPattern = /^s3:\/\/([a-zA-Z0-9.-]+)\/(.+)$/;
    return s3UrlPattern.test(url);
  }
}
