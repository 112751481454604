import { Injectable } from '@angular/core';
import {
  Shift,
  Stay,
  StayDTO,
  StayStatus,
  StaySuggestionInfo,
  StayType,
  TimelineStayManagementDrawerFormFieldEnum,
  TimelineStayManagementDrawerFormValue,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { determineShiftRenderDatetime } from '@wilson/shift-timeline/services';

@Injectable({
  providedIn: 'root',
})
export class StaysDrawerHelperService {
  public preparePrefillEditStayObject(
    value: Stay | null,
  ): Partial<TimelineStayManagementDrawerFormValue | undefined> {
    if (value) {
      const staysPrefillObject: Partial<TimelineStayManagementDrawerFormValue> =
        {
          [TimelineStayManagementDrawerFormFieldEnum.LocationId]:
            value.locationId,
          [TimelineStayManagementDrawerFormFieldEnum.StayStatus]: value.status,
          [TimelineStayManagementDrawerFormFieldEnum.StayType]: value.type,
          [TimelineStayManagementDrawerFormFieldEnum.TimeFrame]: [
            new Date(value.startDatetime),
            new Date(value.endDatetime),
          ],
          [TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime]: new Date(
            value.startDatetime,
          ),
          [TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime]:
            new Date(value.endDatetime),
          [TimelineStayManagementDrawerFormFieldEnum.PriceTotal]:
            value.priceTotal as number,
          [TimelineStayManagementDrawerFormFieldEnum.PaidNights]:
            value.numPaidNights as number,

          [TimelineStayManagementDrawerFormFieldEnum.AccomodationName]:
            value.accommodationName as string,
          [TimelineStayManagementDrawerFormFieldEnum.Address]:
            value.address as string,
          [TimelineStayManagementDrawerFormFieldEnum.PhoneNumber]:
            value.phoneNumber as string,
          [TimelineStayManagementDrawerFormFieldEnum.Note]:
            value.note as string,
          [TimelineStayManagementDrawerFormFieldEnum.UserId]: value.userId,
          [TimelineStayManagementDrawerFormFieldEnum.CancellationDate]:
            value.cancellationDate ? new Date(value.cancellationDate) : null,
          [TimelineStayManagementDrawerFormFieldEnum.Attachments]:
            value.attachments || [],
        };
      return staysPrefillObject;
    } else {
      return undefined;
    }
  }

  public preparePrefillCreateStayObject(
    previousShift:
      | (Shift &
          WithPreparedAttributes & {
            id: string;
          })
      | null,
    nextShift:
      | (Shift &
          WithPreparedAttributes & {
            id: string;
          })
      | null,
  ): Partial<TimelineStayManagementDrawerFormValue> | undefined {
    if (previousShift && nextShift) {
      const determinedPreviousShiftDetails =
        determineShiftRenderDatetime(previousShift);
      const determinedNextShiftDetails =
        determineShiftRenderDatetime(nextShift);
      const previousLocationId = previousShift.reportedEndLocationId
        ? previousShift.reportedEndLocationId
        : previousShift.endLocationId;

      const staysPrefillObject: Partial<TimelineStayManagementDrawerFormValue> =
        {
          [TimelineStayManagementDrawerFormFieldEnum.IsOverNightStayRequired]:
            !!previousShift.ignoreStayNecessary,
          [TimelineStayManagementDrawerFormFieldEnum.LocationId]:
            previousLocationId,
          [TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime]: new Date(
            determinedPreviousShiftDetails.endDatetime.date,
          ),
          [TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime]:
            new Date(determinedNextShiftDetails.startDatetime.date),
          [TimelineStayManagementDrawerFormFieldEnum.TimeFrame]: [
            new Date(determinedPreviousShiftDetails.endDatetime.date),
            new Date(determinedNextShiftDetails.startDatetime.date),
          ],
          [TimelineStayManagementDrawerFormFieldEnum.UserId]:
            previousShift.userId,
          [TimelineStayManagementDrawerFormFieldEnum.Attachments]: [],
        };

      return staysPrefillObject;
    } else {
      return undefined;
    }
  }

  public preparePrefillCreateStayFromBasicInfo(
    staySuggestionInfo: StaySuggestionInfo | null,
  ): Partial<TimelineStayManagementDrawerFormValue> | undefined {
    if (staySuggestionInfo) {
      const dateTime = new Date(staySuggestionInfo.date);
      const staysPrefillObject: Partial<TimelineStayManagementDrawerFormValue> =
        {
          [TimelineStayManagementDrawerFormFieldEnum.LocationId]:
            staySuggestionInfo.locationId,
          [TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime]: dateTime,
          [TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime]:
            dateTime,
          [TimelineStayManagementDrawerFormFieldEnum.TimeFrame]: [
            dateTime,
            dateTime,
          ],
          [TimelineStayManagementDrawerFormFieldEnum.UserId]:
            staySuggestionInfo.userId,
          [TimelineStayManagementDrawerFormFieldEnum.CancellationDate]:
            staySuggestionInfo.cancellationDate
              ? new Date(staySuggestionInfo.cancellationDate)
              : null,
          [TimelineStayManagementDrawerFormFieldEnum.Attachments]: [],
        };

      return staysPrefillObject;
    } else {
      return undefined;
    }
  }

  public prepareFormFillValuesFromPreviousAccomodations(
    stay: Stay,
  ): Partial<TimelineStayManagementDrawerFormValue | undefined> {
    if (stay) {
      const staysPrefillObject: Partial<TimelineStayManagementDrawerFormValue> =
        {
          [TimelineStayManagementDrawerFormFieldEnum.AccomodationName]:
            stay.accommodationName,
          [TimelineStayManagementDrawerFormFieldEnum.Address]: stay.address,
          [TimelineStayManagementDrawerFormFieldEnum.PaidNights]:
            stay.numPaidNights,
          [TimelineStayManagementDrawerFormFieldEnum.PhoneNumber]:
            stay.phoneNumber,
          [TimelineStayManagementDrawerFormFieldEnum.PriceTotal]:
            stay.priceTotal,
        };

      return staysPrefillObject;
    } else {
      return undefined;
    }
  }

  public prepareStaysObject(
    formValue: Partial<TimelineStayManagementDrawerFormValue>,
    defaultValues?: Stay,
  ): StayDTO {
    const staysObject: StayDTO = {
      cancellationDate:
        formValue[
          TimelineStayManagementDrawerFormFieldEnum.CancellationDate
        ]?.toISOString() ||
        defaultValues?.cancellationDate ||
        null,
      userId:
        formValue[TimelineStayManagementDrawerFormFieldEnum.UserId] ||
        defaultValues?.userId ||
        '',
      locationId:
        formValue[TimelineStayManagementDrawerFormFieldEnum.LocationId] ||
        defaultValues?.locationId ||
        '',
      type:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.StayType
        ] as StayType) || defaultValues?.type,
      startDatetime:
        new Date(
          formValue[
            TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime
          ] as Date,
        ).toISOString() ||
        defaultValues?.startDatetime ||
        '',
      endDatetime:
        new Date(
          formValue[
            TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime
          ] as Date,
        ).toISOString() ||
        defaultValues?.endDatetime ||
        '',
      accommodationName:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.AccomodationName
        ] as string) ||
        defaultValues?.accommodationName ||
        '',
      address:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.Address
        ] as string) ||
        defaultValues?.address ||
        '',
      note:
        (formValue[TimelineStayManagementDrawerFormFieldEnum.Note] as string) ||
        defaultValues?.note ||
        '',
      numPaidNights:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.PaidNights
        ] as number) || defaultValues?.numPaidNights,
      phoneNumber:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.PhoneNumber
        ] as string) || defaultValues?.phoneNumber,
      priceTotal:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.PriceTotal
        ] as number) || defaultValues?.priceTotal,
      status:
        (formValue[
          TimelineStayManagementDrawerFormFieldEnum.StayStatus
        ] as StayStatus) || defaultValues?.status,
    };
    return staysObject;
  }
}
