import { Injectable } from '@angular/core';
import {
  State,
  Action,
  StateContext,
  Select,
  createSelector,
} from '@ngxs/store';
import { GeoLocation } from '@wilson/interfaces';
import { LocationsService } from '../locations.service';
import { GetLocationAction } from './location.actions';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface LocationStateModel {
  items: {
    [key in string]: GeoLocation;
  };
}

const defaults = {
  items: {},
};

@State<LocationStateModel>({
  name: 'location',
  defaults,
})
@Injectable()
export class LocationState {
  private inflightRequests = new Map<string, Observable<GeoLocation>>();

  constructor(private locationsService: LocationsService) {}

  @Select()
  static location(id: string) {
    return createSelector([LocationState], (state: LocationStateModel) => {
      return state.items[id];
    });
  }

  @Action(GetLocationAction)
  fetchGeolocation(
    { getState, patchState }: StateContext<LocationStateModel>,
    { id }: GetLocationAction,
  ) {
    const state = getState();
    if (this.inflightRequests.has(id)) {
      return this.inflightRequests.get(id);
    } else {
      const request = this.locationsService.get(id).pipe(
        tap((location) => {
          patchState({
            items: {
              ...state.items,
              [id]: location,
            },
          });
        }),
        finalize(() => {
          this.inflightRequests.delete(id);
        }),
      );
      this.inflightRequests.set(id, request);
      return request;
    }
  }
}
