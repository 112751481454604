import { Injectable } from '@angular/core';
import { GeoLocation } from '@wilson/interfaces';
import { LocationNamePipe } from '@wilson/pipes';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeoLocationFilterService {
  constructor(private readonly locationNamePipe: LocationNamePipe) {}

  filterGeoLocations(
    searchKey: string,
    searchTargets: Observable<Pick<GeoLocation, 'name' | 'locationCode'>[]>,
    maxResults = 25,
  ): Observable<Pick<GeoLocation, 'name' | 'locationCode' | 'id'>[]> {
    searchKey = searchKey.trim();
    return searchTargets.pipe(
      map((targets) => {
        return targets
          .filter(
            (location) =>
              location.name
                .toLocaleLowerCase()
                .includes(searchKey.toLocaleLowerCase()) ||
              (location.name + location.locationCode)
                .toLocaleLowerCase()
                .includes(searchKey.toLocaleLowerCase()) ||
              (location.locationCode &&
                location.locationCode
                  .toLocaleLowerCase()
                  .includes(searchKey.toLocaleLowerCase())) ||
              this.locationNamePipe
                .transform(location)
                .toLocaleLowerCase()
                .includes(searchKey.toLocaleLowerCase()),
          )
          .slice(0, maxResults);
      }),
    );
  }
}
