import { Injectable } from '@angular/core';
import { StateContext, Store } from '@ngxs/store';
import { FeaturePurePipe } from '@wilson/authorization';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  PreferenceSettings,
  PreferencesGatewayService,
} from '@wilson/preferences/core';
import { combineLatest, map, take, tap } from 'rxjs';
import { FetchAndResolveLocationSettingsRemotely } from '../location-preferences/location-preferences.actions';
import { FetchShiftTimePreferenceSettingAction } from '../shift-time-preference-setting/shift-time-preference-setting.actions';
import { FetchUserGeneralPreferences } from '../user-general-preferences/user-general-preferences.actions';
import { FetchSelectedLocation } from '../user-location-preferences/user-location-preferences.actions';
import { FetchUserShiftTimePreferenceAction } from '../user-shift-time-preference/user-shift-time-preference.actions';
import { FetchUserTimePreferences } from '../user-time-preferences/user-time-preferences.actions';
import { FetchSelectedVehicles } from '../user-vehicle-preferences/user-vehicle-preferences.actions';
import { FetchVehiclePreferenceSetting } from '../vehicle-preferences/vehicle-preferences.actions';
import { FetchPreferenceSettings } from './preference-settings.actions';
import {
  PREFERENCE_SETTINGS_STATE_KEY,
  PreferenceSettingsStateModel,
} from './preference-settings.state';
import { FeatureName } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PreferenceSettingsStateService {
  constructor(
    private store: Store,
    private readonly preferencesGatewayService: PreferencesGatewayService,
    private readonly featurePurePipe: FeaturePurePipe,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  checkAndLoadPreferenceData(withSubSettingsFetch = true) {
    this.hasPreferencePermission()
      .pipe(take(1))
      .subscribe((hasPreferencePermission) => {
        if (hasPreferencePermission) {
          this.store
            .dispatch(new FetchPreferenceSettings())
            .pipe(
              take(1),
              tap(
                (state: {
                  [PREFERENCE_SETTINGS_STATE_KEY]: PreferenceSettingsStateModel;
                }) => {
                  if (withSubSettingsFetch) {
                    this.triggerFetchForSubSettings(
                      state[PREFERENCE_SETTINGS_STATE_KEY],
                    );
                  }
                },
              ),
            )
            .subscribe();
        }
      });
  }

  hasPreferencePermission() {
    return combineLatest([
      this.featurePurePipe.transform(FeatureName.Preference),
      this.featureFlagPurePipe.transform('operational-preferences'),
    ]).pipe(
      map(([hasPreferenceFeatureEnabled, hasPreferenceFeatureFlagEnabled]) => {
        return hasPreferenceFeatureEnabled && hasPreferenceFeatureFlagEnabled;
      }),
    );
  }

  triggerFetchForSubSettings(currentState: PreferenceSettingsStateModel) {
    if (currentState.isEnabled) {
      if (currentState.isLocationPrefEnabled) {
        this.store.dispatch(new FetchAndResolveLocationSettingsRemotely());
        this.store.dispatch(new FetchSelectedLocation());
      }
      if (currentState.isVehiclePrefEnabled) {
        this.store.dispatch(new FetchSelectedVehicles());
        this.store.dispatch(new FetchVehiclePreferenceSetting());
      }
      if (
        currentState.isWorkTimePrefEnabled ||
        currentState.isRestTimePrefEnabled
      ) {
        this.store.dispatch(new FetchUserTimePreferences());
      }
      if (
        currentState.isEndShiftAtHomeEnabled ||
        currentState.isLongShiftPrefEnabled
      ) {
        this.store.dispatch(new FetchUserGeneralPreferences());
      }
      if (currentState.isShiftTimePrefEnabled) {
        this.store.dispatch(new FetchShiftTimePreferenceSettingAction());
        this.store.dispatch(new FetchUserShiftTimePreferenceAction());
      }
    }
  }

  async updateSettingInStateAndServer(
    { patchState, getState }: StateContext<PreferenceSettingsStateModel>,
    newSettings: Partial<PreferenceSettings>,
    previousSettings: Partial<PreferenceSettings>,
  ) {
    patchState(newSettings);

    try {
      return await this.preferencesGatewayService.updateSetting(
        getState().id,
        newSettings,
      );
    } catch (e) {
      patchState(previousSettings);
      return Promise.reject(e);
    }
  }
}
