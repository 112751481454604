/// <reference types="@types/googlemaps" />

import { Injectable } from '@angular/core';
import { Address, GeoLocation } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesService {
  public addressEvent(
    term: string,
  ): Promise<google.maps.places.QueryAutocompletePrediction[]> {
    return new Promise((resolve) => {
      const displaySuggestions = (
        predictions: google.maps.places.QueryAutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus,
      ) => {
        if (
          status !== google.maps.places.PlacesServiceStatus.OK ||
          !predictions
        ) {
          return;
        }

        resolve(predictions);
      };
      const service = new google.maps.places.AutocompleteService();
      service.getQueryPredictions({ input: term }, displaySuggestions);
    });
  }

  public getGeoLocationFromGooglePlace(
    // eslint-disable-next-line
    location: any,
    callBack: (result: GeoLocation) => void,
  ) {
    if (location.place_id) {
      const request = {
        placeId: location.place_id,
        fields: [
          'name',
          'address_component',
          'type',
          'formatted_address',
          'geometry',
          'place_id',
          'reference',
          'url',
          'vicinity',
          'website',
        ],
      };
      const service = new google.maps.places.PlacesService(
        document.createElement('div'),
      );
      service.getDetails(request, (place) => {
        callBack(this.transformLocationObjectFromGoogleMaps(place));
      });
    } else {
      callBack(this.transformLocationObjectFromGoogleMaps(location));
    }
  }

  // eslint-disable-next-line
  private transformLocationObjectFromGoogleMaps(event: any): GeoLocation {
    const location = event.geometry.location;
    const lat =
      typeof location.lat() === 'number' ? location.lat() : location.lat;
    const lng =
      typeof location.lng() === 'number' ? location.lng() : location.lng;

    const address: Address = {
      street: ' ',
      postCode: ' ',
      city: ' ',
      state: ' ',
      country: ' ',
    };

    for (const data of event.address_components) {
      switch (data?.types[0]) {
        case 'route':
          address.street = data.long_name;
          break;
        case 'street_number':
          address.street += ` ${data.long_name}`;
          break;
        case 'postal_code':
          address.postCode = data.long_name;
          break;
        case 'locality':
          address.city = data.long_name;
          break;
        case 'administrative_area_level_1':
          address.state = data.long_name;
          break;
        case 'country':
          address.country = data.long_name;
          break;
        default:
          break;
      }
    }

    const geoLocation: GeoLocation = {
      name: event.formatted_address,
      locationCode: null,
      latitude: lat,
      longitude: lng,
      locationCategoryId: '0e892550-509f-491f-9021-d37860d8e6e0', //category address
      addressId: null,
      address,
    };

    return geoLocation;
  }
}
