import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Agreement, Client } from '@wilson/clients/interfaces';
import { map, Observable, of, shareReplay } from 'rxjs';
import { ClientsService } from '@wilson/clients/services';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { cloneDeep } from 'lodash';

export interface ChangeAgreementModalInputData {
  agreement?: Agreement;
  description: string;
}

@Component({
  selector: 'wilson-change-agreement-modal',
  templateUrl: './change-agreement-modal.component.html',
  styleUrls: ['./change-agreement-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeAgreementModalComponent implements OnInit {
  description: string;
  agreement: Agreement | null;

  public agreements$: Observable<NzCascaderOption[]> = of([]);
  private searchString!: string;

  nzSearch = {
    filter: (inputValue: string, path: NzCascaderOption[]) => {
      this.searchString = inputValue.toLowerCase();
      return path.some((option) =>
        option.label?.toLowerCase().includes(this.searchString),
      );
    },
  };

  constructor(
    private readonly clientsService: ClientsService,
    @Inject(NZ_MODAL_DATA) private data: ChangeAgreementModalInputData,
  ) {}

  ngOnInit(): void {
    this.description = this.data.description;
    this.agreement = this.data.agreement
      ? cloneDeep(this.data.agreement)
      : null;

    this.agreements$ = this.clientsService
      .getMany({ relations: ['agreements'], limit: 0 })
      .pipe(
        map((clients) => {
          const result: NzCascaderOption[] = [];
          clients.data.forEach((client: Client) => {
            if (client.agreements?.length) {
              result.push({
                value: client.id,
                label: client.name,
                client: client,
                children: client.agreements.map((agreement) => {
                  return {
                    value: agreement.id,
                    label: agreement.name,
                    isLeaf: true,
                    agreement: agreement,
                  };
                }),
              });
            }
          });
          return result;
        }),
        shareReplay(),
      );
  }

  setAgreement(event: NzCascaderOption[]) {
    if (event && event.length) {
      this.agreement = {
        ...event[1].agreement,
        client: event[0].client,
      };
    } else {
      this.agreement = null;
    }
  }
}
