import { GridApi } from 'ag-grid-community';

export const ICellRendererMock = {
  fullWidth: true,
  pinned: 'SOME_NAME',
  value: 'SOME_VALUE',
  valueFormatted: 'SOME_VALUE',
  data: 'SOME_VALUE',
  node: 'SOME_VALUE',
  colDef: 'SOME_VALUE',
  column: 'SOME_VALUE',
  $scope: 'SOME_VALUE',
  rowIndex: 10,
  api: {
    setDomLayout: () => 'SOME_VALUE',
    sizeColumnsToFit: () => 'SOME_VALUE',
    setQuickFilter: () => 'SOME_VALUE',
    getSelectedNodes: () => 'SOME_VALUE',
  } as unknown as GridApi,
  columnApi: 'SOME_VALUE',
  context: 'SOME_VALUE',
  eGridCell: HTMLElement,
  eParentOfValue: HTMLElement,
  getValue: () => 'SOME_VALUE',
  setValue: (value: 'SOME_VALUE') => 'SOME_VALUE',
  formatValue: (value: 'SOME_VALUE') => 'SOME_VALUE',
  refreshCell: () => 'SOME_VALUE',
  addRenderedRowListener: (eventType: string, listener: any) => 'SOME_VALUE',
  registerRowDragger: (
    rowDraggerElement: HTMLElement,
    dragStartPixels: number,
    value: string,
  ) => 'SOME_VALUE',
};
