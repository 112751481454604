<nz-drawer
  [nzVisible]="true"
  [nzMask]="showMask"
  nzPlacement="right"
  [nzTitle]="'page.shift_timeline.hotel_stay.drawer.title' | translate"
  [nzFooter]="drawerFooter"
  [nzClosable]="false"
  (nzOnClose)="closeDrawer()"
>
  <ng-container *nzDrawerContent>
    <wilson-timeline-stays-drawer-form
      [isUserFieldReadOnly]="isUserFieldReadOnly"
      [prefillValues]="prefillValues"
      [sector]="sector"
      (isInvalid)="isFormInvalidSignal.set($event)"
      (value)="formValueSignal.set($event)"
      (handleInvoice)="handleInvoice.emit($event)"
      (handleAttachments)="handleAttachments.emit($event)"
    ></wilson-timeline-stays-drawer-form>
  </ng-container>

  <ng-template #drawerFooter>
    <div class="drawer__footer">
      <button
        (click)="closeDrawer()"
        nz-button
        nzType="default"
        class="w-100"
        [disabled]="disableButtons"
      >
        {{ 'general.cancel' | translate }}
      </button>
      <button
        [nzLoading]="isSaving"
        [disabled]="isFormInvalidSignal() || disableButtons"
        (click)="emitConfirmEvent()"
        nz-button
        nzType="primary"
        class="w-100"
      >
        {{ 'general.accept' | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>
