import {
  GeoLocation,
  OperationStatus,
  OrganizationalUnit,
} from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import { CreateNewViaDragAndDropService } from '../create-new-via-drag-and-drop.service';
import { createFullActivityObject } from '@wilson/non-domain-specific/activities-helpers/services';

export function generateDummyActivity({
  orgUnit,
  location,
  activityStartDate,
  activityEndDate,
  activityCategoryId,
}: {
  orgUnit: OrganizationalUnit;
  location: GeoLocation;
  activityStartDate: string;
  activityEndDate: string;
  activityCategoryId: string;
}) {
  return {
    ...createFullActivityObject(),
    id: CreateNewViaDragAndDropService.MOCK_ACTIVITY_ID,
    activityCategoryId: activityCategoryId,
    activityCategory: {
      id: '73b6f3fc-8d47-4cd0-8e4a-a3a3756e8ce7',
      version: 1,
      name: 'train-drive',
      isMain: true,
      isPayableTime: true,
      isProductiveTime: true,
      sectorIds: [],
      isEnabled: false,
    },
    createdFrom: WilsonApp.Portal,
    endDatetime: activityEndDate,
    endLocationId: orgUnit.locationId as string,
    endLocation: location,
    jobId: null,
    name: '',
    operationalStatus: OperationStatus.NotStarted,
    professionId: null,
    serviceId: null,
    shiftId: null,
    startDatetime: activityStartDate,
    startLocationId: orgUnit.locationId as string,
    startLocation: location,
    viaLocationIds: [],
  };
}
