import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  ActivitiesService,
  ActivityValidationService,
} from '@wilson/activities';
import { DefaultActivityCategoryService } from '@wilson/non-domain-specific/activities-helpers/services';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  Activity,
  DefaultActivityCategory,
  OperationStatus,
  Sector,
  WilsonApp,
} from '@wilson/interfaces';
import { SectorState } from '@wilson/sectors';
import { DateTimeFormat } from '@wilson/utils';
import { addHours, format } from 'date-fns';
import { firstValueFrom } from 'rxjs';

interface AddActivityParameters {
  insertIndex: number;
  activityFormArray: FormArray;
  isShiftSeries: boolean;
  setEndLocationAndTimeToNextActivity?: boolean;
  prefilledActivityCategoryName?: DefaultActivityCategory;
  canEditServiceActivities?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ActivityInsertionService {
  constructor(
    private readonly store: Store,
    private readonly activityValidationService: ActivityValidationService,
    private readonly formBuilder: FormBuilder,
    private readonly activitiesService: ActivitiesService,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
    private readonly defaultActivityCategoryService: DefaultActivityCategoryService,
  ) {}

  public async addActivity({
    insertIndex,
    activityFormArray,
    isShiftSeries,
    setEndLocationAndTimeToNextActivity = false,
    prefilledActivityCategoryName,
  }: AddActivityParameters) {
    const firstSector: Sector | null = this.store.selectSnapshot<Sector[]>(
      SectorState.sectors,
    )[0];

    if (firstSector?.name === 'rail') {
      await this.addActivityInRailSector({
        insertIndex,
        activityFormArray,
        isShiftSeries,
        setEndLocationAndTimeToNextActivity,
        prefilledActivityCategoryName,
      });
    } else {
      const activityCategory = await this.getDefaultActivityCategory();
      const startTime =
        insertIndex === 0 && activityFormArray.value[insertIndex]
          ? activityFormArray.value[insertIndex].serviceId && !isShiftSeries
            ? format(
                new Date(activityFormArray.value[insertIndex].startDatetime),
                DateTimeFormat.TimeFormat,
              )
            : activityFormArray.value[insertIndex].startDatetime
          : activityFormArray.value[insertIndex - 1]
          ? activityFormArray.value[insertIndex - 1].serviceId && !isShiftSeries
            ? format(
                new Date(activityFormArray.value[insertIndex - 1].endDatetime),
                DateTimeFormat.TimeFormat,
              )
            : activityFormArray.value[insertIndex - 1].endDatetime
          : null;
      activityFormArray.insert(
        insertIndex,
        this.formBuilder.group(
          {
            id: [null],
            name: [null],
            startDatetime: [startTime, Validators.required],
            endDatetime: [startTime, Validators.required],
            activityCategoryId: [activityCategory?.id, Validators.required],
            activityCategory: [activityCategory],
            startLocation: [
              insertIndex === 0
                ? activityFormArray.value[insertIndex]?.startLocation
                : activityFormArray.value[insertIndex - 1]?.endLocation,
              Validators.required,
            ],
            endLocation: [
              insertIndex === 0
                ? activityFormArray.value[insertIndex]?.startLocation
                : activityFormArray.value[insertIndex - 1]?.endLocation,
              Validators.required,
            ],
            shiftId: [null],
            serviceId: [null],
            operationalStatus: [OperationStatus.NotStarted],
            activityReports: [[]],
            professionId: [null, Validators.required],
            profession: [null],
          },
          {
            validators:
              this.activityValidationService.plannedTimesValidatorFn(),
          },
        ),
      );
    }
  }

  private async addActivityInRailSector({
    insertIndex,
    activityFormArray,
    isShiftSeries,
    setEndLocationAndTimeToNextActivity = false,
    prefilledActivityCategoryName,
  }: AddActivityParameters) {
    let activity = activityFormArray.value[insertIndex - 1];

    const canEditServiceActivities = await firstValueFrom(
      this.featureFlagPurePipe.transform(
        'portal-edit-service-activity-serviceseries-activitytemplate',
      ),
    );

    for (let i = insertIndex - 1; i >= 0; i--) {
      if (activityFormArray.value[i].createdFrom !== WilsonApp.Mobile) {
        activity = activityFormArray.value[i];
        break;
      }
    }
    const startTime = this.getActivityStartTimeOfRailSector(
      insertIndex,
      activityFormArray,
      isShiftSeries,
      activity,
      canEditServiceActivities,
    );

    const startTimePlusOneHour = format(
      addHours(
        new Date(
          `${format(
            new Date(),
            DateTimeFormat.DatabaseDateFormat,
          )} ${startTime}`,
        ),
        1,
      ),
      DateTimeFormat.TimeFormat,
    );

    const endTime = setEndLocationAndTimeToNextActivity
      ? this.getStartTimeOfNextRailSectorActivity(
          insertIndex,
          activityFormArray,
        )
      : startTimePlusOneHour;

    const endLocation = setEndLocationAndTimeToNextActivity
      ? this.getLocationOfNextActivity(insertIndex, activityFormArray)
      : this.getDefaultLocationOfNextActivity(insertIndex, activityFormArray);

    const endLocationId = setEndLocationAndTimeToNextActivity
      ? this.getLocationIdOfNextActivity(insertIndex, activityFormArray)
      : this.getDefaultLocationIdOfNextActivity(insertIndex, activityFormArray);

    const defaultActivityCategory = await this.getDefaultActivityCategory();
    const activityCategory = prefilledActivityCategoryName
      ? await this.getActivtyWithName(prefilledActivityCategoryName)
      : defaultActivityCategory
      ? defaultActivityCategory
      : null;

    activityFormArray.insert(
      insertIndex,
      this.formBuilder.group(
        {
          id: [null],
          name: [null],
          startDatetime: [startTime, Validators.required],
          endDatetime: [endTime, Validators.required],
          activityCategoryId: [activityCategory?.id, Validators.required],
          activityCategory: [activityCategory],
          startLocationId: [
            insertIndex === 0
              ? activityFormArray.value[insertIndex]?.startLocationId
              : activityFormArray.value[insertIndex - 1]?.endLocationId,
            Validators.required,
          ],
          startLocation: [
            insertIndex === 0
              ? activityFormArray.value[insertIndex]?.startLocation
              : activityFormArray.value[insertIndex - 1]?.endLocation,
          ],
          endLocationId: [endLocationId, Validators.required],
          endLocation: [endLocation],
          shiftId: [null],
          serviceId: [null],
          operationalStatus: [OperationStatus.NotStarted],
          activityReports: [[]],
          agreementId: [null],
          agreement: [null],
          professionId: [null],
          profession: [null],
        },
        {
          validators: this.activityValidationService.plannedTimesValidatorFn(),
        },
      ),
    );
  }

  private getStartTimeOfNextRailSectorActivity(
    insertIndex: number,
    activityFormArray: FormArray,
  ): string | null {
    const startTime = activityFormArray.value[insertIndex]?.startDatetime;

    return startTime.length !== 5
      ? format(new Date(startTime), DateTimeFormat.TimeFormat)
      : startTime;
  }

  private getLocationOfNextActivity(
    insertIndex: number,
    activityFormArray: FormArray,
  ) {
    return activityFormArray.value[insertIndex]?.startLocation;
  }

  private getDefaultLocationOfNextActivity(
    index: number,
    activityFormArray: FormArray,
  ) {
    return index === 0
      ? activityFormArray.value[index]?.startLocation
      : activityFormArray.value[index - 1]?.endLocation;
  }

  private getLocationIdOfNextActivity(
    insertIndex: number,
    activityFormArray: FormArray,
  ) {
    return activityFormArray.value[insertIndex]?.startLocationId;
  }

  public getDefaultLocationIdOfNextActivity(
    index: number,
    activityFormArray: FormArray,
  ) {
    return index === 0
      ? activityFormArray.value[index]?.endLocationId
      : activityFormArray.value[index - 1]?.endLocationId;
  }

  public getActivityStartTimeOfRailSector(
    index: number,
    activityFormArray: FormArray,
    isShiftSeries: boolean,
    activity: Activity,
    canEditServiceActivities?: boolean,
  ) {
    let startTime: string | null = null;

    if (index === 0 && activityFormArray.value[index]) {
      if (activityFormArray.value[index].serviceId && !isShiftSeries) {
        startTime = format(
          new Date(activityFormArray.value[index].startDatetime),
          DateTimeFormat.TimeFormat,
        );
      } else {
        startTime = activityFormArray.value[index].startDatetime;
      }
    } else if (activity) {
      if (activity.serviceId && !isShiftSeries && !canEditServiceActivities) {
        startTime = format(
          new Date(activity.endDatetime),
          DateTimeFormat.TimeFormat,
        );
      } else {
        startTime =
          activity.endDatetime.length !== 5
            ? format(new Date(activity.endDatetime), DateTimeFormat.TimeFormat)
            : activity.endDatetime;
      }
    }

    return startTime;
  }

  private async getActivtyWithName(
    activityCategoryName: DefaultActivityCategory | undefined,
  ) {
    const categories = await firstValueFrom(
      this.activitiesService.getEnabledActivityCategories(),
    );
    if (categories && activityCategoryName) {
      return categories.find(
        (category) => category.name === activityCategoryName,
      );
    } else {
      return null;
    }
  }

  private async getDefaultActivityCategory() {
    const defaultActivityCategoryId =
      this.defaultActivityCategoryService.getDefaultActivityCategoryId();
    const categories = await firstValueFrom(
      this.activitiesService.getEnabledActivityCategories(),
    );
    if (categories && defaultActivityCategoryId) {
      return categories.find(
        (category) => category.id === defaultActivityCategoryId,
      );
    } else {
      return null;
    }
  }
}
