<div
  *ngIf="{
    isItemInBucket: activityTemplate | isItemInBucket | async,
    canBeShared: activityTemplate | anyActivityCanBeAddedToShare
  } as data"
  nz-tooltip
  nzTooltipOverlayClassName="dark"
  [nzTooltipTitle]="
    tooltipText ??
      (data.isItemInBucket
        ? 'wilson.share.remove_from_share_request'
        : !data.canBeShared
        ? 'wilson.share.agreement_exist'
        : disabled
        ? null
        : 'wilson.share.add_to_share_request') | translate
  "
  [class.cursor-not-allowed]="isIconDisabled"
>
  <div
    class="cursor-pointer text-dark position-relative"
    (click)="
      data.isItemInBucket
        ? removeActivityTemplateFromBucket(activityTemplate.id)
        : addActivityTemplateToBucket(activityTemplate)
    "
    [class.disable-share]="isIconDisabled"
  >
    <i class="fas fa-handshake me-1 my-auto"></i>
    <i
      class="fas action-icon"
      [ngClass]="data.isItemInBucket ? 'fa-minus' : 'fa-plus'"
    ></i>
  </div>
</div>
