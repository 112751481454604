import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  PrefScoreView,
  PreferenceSettings,
  PreferencesGatewayService,
  RolePreferenceSetting,
} from '@wilson/preferences/core';
import { cloneDeep } from 'lodash';
import { tap } from 'rxjs';
import { LocationPreferencesState } from '../location-preferences/location-preferences.state';
import { ShiftTimePreferenceSettingState } from '../shift-time-preference-setting/shift-time-preference-setting.state';
import { UserGeneralPreferencesState } from '../user-general-preferences/user-general-preferences.state';
import { VehiclePreferencesState } from '../vehicle-preferences/vehicle-preferences.state';
import { PreferenceSettingsStateService } from './preference-settings-state.service';
import {
  FetchPreferenceSettings,
  UpdateEndAtHomeDistance,
  UpdateEndShiftAtHome,
  UpdateLocationPreference,
  UpdateLongShiftDuration,
  UpdateLongShiftPreference,
  UpdatePrefScoreView,
  UpdatePreferenceSetting,
  UpdatePreferenceViewRole,
  UpdateRestTimePreference,
  UpdateRestTimePreferenceCount,
  UpdateShiftTimePreference,
  UpdateVehiclePreference,
  UpdateWorkTimePreference,
  UpdateWorkTimePreferenceCount,
} from './preference-settings.actions';

export type PreferenceSettingsStateModel = PreferenceSettings;

const defaults: PreferenceSettingsStateModel = {
  isEnabled: false,
  isLocationPrefEnabled: false,
  isLongShiftPrefEnabled: false,
  isVehiclePrefEnabled: false,
  isWorkTimePrefEnabled: false,
  isRestTimePrefEnabled: false,
  isEndShiftAtHomeEnabled: false,
  isShiftTimePrefEnabled: false,
  userHomeLocationRadiusInKm: 0,
  prefScoreView: PrefScoreView.None,
  maxCountWorkTimePrefMobile: 0,
  maxCountRestTimePrefMobile: 0,
  maxCountLocationPrefMobile: 0,
  maxCountVehiclePrefMobile: 0,
  organizationalUnitId: '',
  preferenceSettingRole: [],
  longShiftDurationInHours: 0,
};

export const PREFERENCE_SETTINGS_STATE_KEY = 'preferenceSettings';

@State<PreferenceSettingsStateModel>({
  name: PREFERENCE_SETTINGS_STATE_KEY,
  defaults,
  children: [
    LocationPreferencesState,
    UserGeneralPreferencesState,
    VehiclePreferencesState,
    ShiftTimePreferenceSettingState,
  ],
})
@Injectable()
export class PreferenceSettingsState {
  constructor(
    private readonly preferencesGatewayService: PreferencesGatewayService,
    private readonly preferenceSettingsStateService: PreferenceSettingsStateService,
    private readonly store: Store,
  ) {}

  @Selector()
  static longShiftDurationInHours(state: PreferenceSettingsStateModel): number {
    return state.longShiftDurationInHours;
  }

  @Selector()
  static userHomeLocationRadiusInKm(
    state: PreferenceSettingsStateModel,
  ): number {
    return state.userHomeLocationRadiusInKm;
  }

  @Selector()
  static preferenceSettingRole(
    state: PreferenceSettingsStateModel,
  ): RolePreferenceSetting[] {
    return state.preferenceSettingRole;
  }

  @Selector()
  static prefScoreView(state: PreferenceSettingsStateModel): PrefScoreView {
    return state.prefScoreView;
  }

  @Selector()
  static maxCountLocationPrefMobile(
    state: PreferenceSettingsStateModel,
  ): number {
    return state.maxCountLocationPrefMobile;
  }

  @Selector()
  static maxCountVehiclePrefMobile(
    state: PreferenceSettingsStateModel,
  ): number {
    return state.maxCountVehiclePrefMobile;
  }

  @Selector()
  static maxCountWorkTimePrefMobile(
    state: PreferenceSettingsStateModel,
  ): number {
    return state.maxCountWorkTimePrefMobile;
  }

  @Selector()
  static maxCountRestTimePrefMobile(
    state: PreferenceSettingsStateModel,
  ): number {
    return state.maxCountRestTimePrefMobile;
  }

  @Selector()
  static isPreferencesEnabled(state: PreferenceSettingsStateModel): boolean {
    return state.isEnabled;
  }

  @Selector()
  static isShiftTimePrefEnabled(state: PreferenceSettingsStateModel): boolean {
    return state.isShiftTimePrefEnabled;
  }

  @Selector()
  static isLocationPrefEnabled(state: PreferenceSettingsStateModel): boolean {
    return state.isLocationPrefEnabled;
  }

  @Selector()
  static isEndShiftAtHomeEnabled(state: PreferenceSettingsStateModel): boolean {
    return state.isEndShiftAtHomeEnabled;
  }

  @Selector()
  static isLongShiftPrefEnabled(state: PreferenceSettingsStateModel): boolean {
    return state.isLongShiftPrefEnabled;
  }

  @Selector()
  static isVehiclePreferenceEnabled(
    state: PreferenceSettingsStateModel,
  ): boolean {
    return state.isVehiclePrefEnabled;
  }

  @Selector()
  static isRestTimePrefEnabled(state: PreferenceSettingsStateModel): boolean {
    return state.isRestTimePrefEnabled;
  }

  @Selector()
  static isWorkTimePrefEnabled(state: PreferenceSettingsStateModel): boolean {
    return state.isWorkTimePrefEnabled;
  }

  @Selector()
  static isShiftPreferenceEnabled(
    state: PreferenceSettingsStateModel,
  ): boolean {
    return state.isShiftTimePrefEnabled;
  }

  @Action(UpdatePreferenceViewRole)
  async updatePreferenceViewRole(
    { patchState, getState }: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdatePreferenceViewRole,
  ) {
    const currentRoleSetting = getState().preferenceSettingRole;

    const newRoleSetting = cloneDeep(currentRoleSetting);

    const interestedSetting = newRoleSetting.find(
      (setting) => setting.id === payload.preferenceId,
    );
    interestedSetting.isEnabled = payload.isEnabled;

    try {
      patchState({ preferenceSettingRole: newRoleSetting });
      return await this.preferencesGatewayService.updateRoleSetting(
        getState().id,
        { id: interestedSetting.id, isEnabled: interestedSetting.isEnabled },
      );
    } catch (e) {
      patchState({ preferenceSettingRole: currentRoleSetting });
      return Promise.reject(e);
    }
  }

  @Action(UpdateRestTimePreference)
  updateRestTimePreference(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateRestTimePreference,
  ) {
    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { isRestTimePrefEnabled: payload.value },
      { isRestTimePrefEnabled: !payload.value },
    );
  }

  @Action(UpdatePrefScoreView)
  updatePrefScoreView(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdatePrefScoreView,
  ) {
    const currentView = context.getState().prefScoreView;

    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { prefScoreView: payload.view },
      { prefScoreView: currentView },
    );
  }

  @Action(UpdateEndAtHomeDistance)
  updateEndAtHomeDistance(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateEndAtHomeDistance,
  ) {
    const currentValue = context.getState().userHomeLocationRadiusInKm;

    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { userHomeLocationRadiusInKm: payload.value },
      { userHomeLocationRadiusInKm: currentValue },
    );
  }

  @Action(UpdateEndShiftAtHome)
  updateEndShiftAtHome(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateEndShiftAtHome,
  ) {
    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { isEndShiftAtHomeEnabled: payload.value },
      { isEndShiftAtHomeEnabled: !payload.value },
    );
  }

  @Action(UpdateLongShiftDuration)
  updateLongShiftDuration(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateLongShiftDuration,
  ) {
    const currentValue = context.getState().longShiftDurationInHours;

    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { longShiftDurationInHours: payload.value },
      { longShiftDurationInHours: currentValue },
    );
  }

  @Action(UpdateLongShiftPreference)
  updateLongShiftPreference(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateLongShiftPreference,
  ) {
    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { isLongShiftPrefEnabled: payload.value },
      { isLongShiftPrefEnabled: !payload.value },
    );
  }

  @Action(UpdateWorkTimePreferenceCount)
  updateWorkTimePreferenceCount(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateWorkTimePreferenceCount,
  ) {
    const originalCount = context.getState().maxCountWorkTimePrefMobile;

    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { maxCountWorkTimePrefMobile: payload.count },
      { maxCountWorkTimePrefMobile: originalCount },
    );
  }

  @Action(UpdateRestTimePreferenceCount)
  updateRestTimePreferenceCount(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateRestTimePreferenceCount,
  ) {
    const originalCount = context.getState().maxCountRestTimePrefMobile;

    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { maxCountRestTimePrefMobile: payload.count },
      { maxCountRestTimePrefMobile: originalCount },
    );
  }

  @Action(UpdateWorkTimePreference)
  updateWorkTimePreference(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateWorkTimePreference,
  ) {
    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { isWorkTimePrefEnabled: payload.value },
      { isWorkTimePrefEnabled: !payload.value },
    );
  }

  @Action(UpdatePreferenceSetting)
  updatePreferenceSetting(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdatePreferenceSetting,
  ) {
    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { isEnabled: payload.value },
      { isEnabled: !payload.value },
    );
  }

  @Action(UpdateVehiclePreference)
  updateVehiclePreference(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateVehiclePreference,
  ) {
    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { isVehiclePrefEnabled: payload.value },
      { isVehiclePrefEnabled: !payload.value },
    );
  }

  @Action(UpdateShiftTimePreference)
  updateShiftTimePreference(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateShiftTimePreference,
  ) {
    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { isShiftTimePrefEnabled: payload.value },
      { isShiftTimePrefEnabled: !payload.value },
    );
  }

  @Action(UpdateLocationPreference)
  updateLocationPreference(
    context: StateContext<PreferenceSettingsStateModel>,
    { payload }: UpdateLocationPreference,
  ) {
    return this.preferenceSettingsStateService.updateSettingInStateAndServer(
      context,
      { isLocationPrefEnabled: payload.value },
      { isLocationPrefEnabled: !payload.value },
    );
  }

  @Action(FetchPreferenceSettings)
  fetchPreferenceSettings({
    patchState,
  }: StateContext<PreferenceSettingsStateModel>) {
    return this.preferencesGatewayService.getAll().pipe(
      tap((settings: PreferenceSettings) => {
        if (settings) {
          patchState({
            ...settings,
          });
        }
      }),
    );
  }
}
