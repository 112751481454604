import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService, TypeOrmFindManyOptions } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  IntervalDate,
  OvertimeDetail,
  ResolvedUser,
  User,
  UserOvertime,
  UserSetting,
  UserWithShiftAcceptanceStatus,
  VacationStatus,
  FindConditions,
} from '@wilson/interfaces';
import { stringify } from 'qs';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';

@Injectable()
export class AccountService extends BackendService<ResolvedUser> {
  protected readonly path = 'users';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  getUser(userId: string, options: TypeOrmFindManyOptions = {}) {
    const params = stringify({ ...options });
    return this.http.get<User>(`${this.config.host}/users/${userId}?${params}`);
  }

  getResolvedUser(userId: string) {
    return this.http.post<ResolvedUser[]>(
      `${this.config.host}/users/resolved`,
      { id: userId },
    );
  }

  getResolvedOrganizationalUnitUsers() {
    return this.http.post<ResolvedUser[]>(
      `${this.config.host}/users/resolved`,
      {},
    );
  }

  updateUser(user: Partial<User>) {
    return firstValueFrom(
      this.http.patch(`${this.config.host}/users/${user.id}`, user),
    );
  }

  /**
   * Delete user by id
   * @param userId id of the user object
   * @returns Observable
   */
  deleteUser(userId: string) {
    return firstValueFrom(
      this.http.delete(`${this.config.host}/users/${userId}`),
    );
  }

  /**
   * Get all users of a certain root organizational unit.
   * In the backend, this method resolves down to all sub organizational units of a root and returns the assigened users.
   * @param rootOrgUnitId id of the root organizational unit
   * @returns Observable
   */
  getRootOrgUnitUsers(rootOrgUnitId: string) {
    return this.http.get<User[]>(
      `${this.config.host}/users/organizational-unit/root/${rootOrgUnitId}`,
    );
  }

  /**
   * Get all users of a certain organizational unit
   * @param orgUnitId id of the organizational unit
   * @returns Observable
   */
  getOrgUnitUsers(orgUnitId: string) {
    return this.http.post<User[]>(`${this.config.host}/users/search`, {
      organizationalUnitId: orgUnitId,
    });
  }

  public getUsers(
    orgUnitIds?: string | string[],
    conditions?: FindConditions<User>,
    isUserRoles?: boolean,
    relationKey?: string,
  ): Observable<User[]> {
    const params: FindConditions<User> = conditions ? { ...conditions } : {};
    const userRoles = isUserRoles ? `&relations[]=userRoles` : '';
    let orgUnits = '';
    const relations = relationKey ? `&relations[0]=${relationKey}` : '';
    if (orgUnitIds) {
      const isArray = typeof orgUnitIds !== 'string';
      if (isArray) {
        (orgUnitIds as unknown as string[]).forEach((id, index) => {
          orgUnits =
            orgUnits +
            (index === 0 ? '' : '&') +
            `where[organizationalUnitIds]=${id}${relations}`;
        });
      } else {
        orgUnits = `where[organizationalUnitId]=${orgUnitIds}${relations}`;
      }
    }

    type queryParamLike = HttpParams | Record<string, string | string[]>;
    return this.http.get<User[]>(
      `${this.config.host}/users?${orgUnits}${userRoles}${relations}`,
      {
        params: params as queryParamLike,
      },
    );
  }

  public getUserVacationStatus(
    userId: string | undefined,
    year: number,
  ): Observable<VacationStatus> {
    return this.http.get<VacationStatus>(
      `${this.config.host}/users/${userId}/vacation-status?where[year]=${year}`,
    );
  }

  /**
   * Create a user object and trigger the signup workflow.
   * Users you create with this method get an automated email with a link to set their initial password.
   * @param orgUnitId id of the organizational unit
   * @returns Promise
   */
  signUp(user: User, withoutEmail: '0' | '1') {
    return firstValueFrom(
      this.http.post<User>(
        `${this.config.host}/auth/signup?withoutEmail=${withoutEmail}`,
        user,
      ),
    );
  }

  /**
   * Get the id of the root organization unit the user belongs to.
   * In the backend, this method resolves up from the the organizational unit the user belongs to
   options: TypeOrmFindManyOptions = {}
   * and returns the respective id of the root organizational unit.
   * @param userId user id
   * @returns Observable
   */
  getUsersRootOrganizationalUnitId(userId: string) {
    return this.http.get<{ rootOrganizationalUnitId: string }>(
      `${this.config.host}/users/organizational-unit-root-id/${userId}`,
    );
  }

  getUserByEmail(email: string): Observable<User[]> {
    return this.http.post<User[]>(`${this.config.host}/users/search`, {
      email,
    });
  }

  getUserOvertimes(
    timeRanges: IntervalDate[],
    options: TypeOrmFindManyOptions = {},
  ): Observable<UserOvertime[]> {
    const params = stringify(options);
    return this.http.post<UserOvertime[]>(
      `${this.config.host}/payroll-transactions/users/overtime?${params}`,
      timeRanges,
    );
  }

  getPayrollCategoriesSum(
    userId: string,
    timeRange: IntervalDate,
  ): Observable<OvertimeDetail[]> {
    return this.http.get<OvertimeDetail[]>(
      `${this.config.host}/payroll-transactions/users/${userId}/payroll-categories-sum?start=${timeRange.start}&end=${timeRange.end}`,
    );
  }

  getUserWithShiftAcceptanceStatus(
    queryParams: Record<string, unknown>,
  ): Observable<UserWithShiftAcceptanceStatus[]> {
    const params = stringify(queryParams);
    return this.http.get<UserWithShiftAcceptanceStatus[]>(
      `${this.config.host}/users/payroll/accepted-shifts?${params}`,
    );
  }

  closeMonthForUsers(userIds: string[], formattedMonth: string) {
    const payload = { userIds, yearAndMonth: formattedMonth };

    return this.http.post(
      `${this.config.host}/payroll-monthly-closures`,
      payload,
    );
  }

  updateSettings(
    userSettings: UserSetting,
    updatedValue: Partial<UserSetting>,
  ) {
    return lastValueFrom(
      this.http.patch(
        `${this.config.host}/user-settings/${userSettings.id}`,
        updatedValue,
      ),
    );
  }
}
