import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-trash-icon',
  template: `
    <div class="d-flex">
      <span
        class="px-2 selected-icon text-secondary"
        [ngClass]="cellValue ? 'fas fa-undo' : 'far fa-trash-alt'"
        (click)="toggleSelection()"
      >
      </span>
    </div>
  `,
  styles: [
    `
      .selected-icon {
        font-size: 22px;
      }
      .selected-icon:hover {
        color: var(--primary-tint) !important;
        cursor: pointer;
      }
    `,
  ],
})
export class TrashIconRendererComponent implements AgRendererComponent {
  public cellValue: boolean | undefined;
  private paramsApi: ICellRendererParams;

  agInit(params: ICellRendererParams) {
    this.paramsApi = params;
    this.refresh(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  toggleSelection() {
    this.cellValue = !this.cellValue;
    this.paramsApi.node.setSelected(this.cellValue);
  }

  private getValueToDisplay(params: ICellRendererParams) {
    return params?.node?.isSelected();
  }
}
