import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  CreatePayrollSetting,
  PayrollSetting,
  UserPayrollSetting,
} from '@wilson/payroll/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PayrollSettingsService extends BackendService<PayrollSetting> {
  protected readonly path = 'payroll-settings';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  public getPayrollSettingAssignedUsers(
    id: string,
  ): Observable<UserPayrollSetting[]> {
    return this.http.get<UserPayrollSetting[]>(
      `${this.config.host}/${this.path}/${id}/active-users`,
    );
  }

  public getActiveUserPayrollSetting(
    userId: string,
    date: string,
  ): Observable<UserPayrollSetting> {
    return this.http.get<UserPayrollSetting>(
      `${this.config.host}/user-payroll-settings/${userId}/active/${date}`,
    );
  }
  public bulkDeleteUsersAssignedPayrollSetting(userIds: string[]) {
    return this.http.patch(`${this.config.host}/user-payroll-settings/delete`, {
      ids: userIds,
    });
  }

  public createUserPayrollSetting(
    payload: CreatePayrollSetting[],
  ): Observable<PayrollSetting> {
    return this.http.post<PayrollSetting>(
      `${this.config.host}/user-payroll-settings`,
      {
        items: payload,
      },
    );
  }
}
