import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { TimelineStayGateway } from '@wilson/api/gateway';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { getTimeZoneCorrectedDateRange } from '@wilson/utils';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { ShiftId, StayId } from '../interfaces';
import { ShiftTimelineDataState } from './shift-timeline-data.state';
import { ShiftTimelineVisibilityStateService } from './shift-timeline-visibility-state.service';
import { TimelineUpdateUserStays } from './shift-timeline-data.actions';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { FeaturePurePipe } from '@wilson/authorization';
import { AblePurePipe } from '@casl/angular';
import { AnyAbility } from '@casl/ability';
import {
  FeatureName,
  RoleAction,
  RolePermissionSubject,
} from '@wilson/interfaces';
import { ShiftTimelineSettingsState } from '../shift-timeline-settings/shift-timeline-settings.state';

@Injectable()
export class TimelineStaysSuggestionService {
  @WilsonState<Record<ShiftId, boolean> | null>(null)
  private shiftsRecommendedStaysInRangeMap!: Record<StayId, boolean> | null;
  shiftsRecommendedStaysInRangeMap$!: Observable<Record<StayId, boolean>>;

  @WilsonState<Record<StayId, boolean> | null>(null)
  private validatedStaysInRangeMap!: Record<StayId, boolean> | null;
  validatedStaysInRangeMap$!: Observable<Record<StayId, boolean> | null>;

  @WilsonState<number | null>(null)
  private refreshKPI!: number | null;
  refreshKPI$!: Observable<number | null>;

  constructor(
    private readonly store: Store,
    private readonly message: NzMessageService,
    private readonly translate: TranslateService,
    protected timelineStayGateway: TimelineStayGateway,
    private shiftTimelineVisibilityStateService: ShiftTimelineVisibilityStateService,

    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
    private readonly featurePurePipe: FeaturePurePipe,
    private readonly ablePurePipe: AblePurePipe<AnyAbility>,
  ) {}

  private refreshStayKPICount() {
    this.refreshKPI = Math.random();
  }

  public resetRecommendedStaysInRangeMap() {
    this.shiftsRecommendedStaysInRangeMap = null;
  }

  async refreshStaysDataForSpecifiedUsers(userIds: string[]) {
    const visibleDateRange = this.store.selectSnapshot(
      ShiftTimelineDataState.visibleDateRange,
    );

    const areStaysVisible = this.store.selectSnapshot(
      ShiftTimelineSettingsState.areStaysVisible,
    );
    const isStaysManagementFeatureFlagEnabled = await firstValueFrom(
      this.featureFlagPurePipe.transform('timeline-stays-management'),
    );
    const isStaysManagementFeatureEnabled = await firstValueFrom(
      this.featurePurePipe.transform(FeatureName.StayManagement),
    );
    const hasRoleToAccessStaysManagement = await firstValueFrom(
      this.ablePurePipe.transform(RoleAction.Read, RolePermissionSubject.Stay),
    );

    if (
      isStaysManagementFeatureEnabled &&
      isStaysManagementFeatureFlagEnabled &&
      hasRoleToAccessStaysManagement &&
      areStaysVisible
    ) {
      firstValueFrom(
        this.updateSingleUserNecessaryStaysInRange({
          dateRange: visibleDateRange as { start: Date; end: Date },
          userIds: userIds,
        }),
      );

      firstValueFrom(
        this.refreshUsersValidatedStays({
          dateRange: visibleDateRange as { start: Date; end: Date },
          userIds: userIds,
        }),
      );
      this.refreshStayKPICount();
    }
  }

  private refreshUsersValidatedStays({
    dateRange,
    userIds,
  }: {
    dateRange: {
      start: Date;
      end: Date;
    };
    userIds: string[];
  }) {
    try {
      const correctedInterval = getTimeZoneCorrectedDateRange({
        start: dateRange.start as Date,
        end: dateRange.end as Date,
      });
      return this.timelineStayGateway
        .getStays({
          startDatetime: correctedInterval.start.toISOString(),
          endDatetime: correctedInterval.end.toISOString(),
          userIds,
        })
        .pipe(
          tap((stays) => {
            if (stays.length) {
              const stayIds: string[] = stays.map((stay) => stay.id);
              firstValueFrom(this.updateUsersValidatedStays(stayIds));
            }
          }),
        );
    } catch (error) {
      console.error(
        'An error occurred while refreshing user stays validations in range:',
        error,
      );

      this.message.error(
        this.translate.instant('general.something_went_wrong'),
      );
      throw error;
    }
  }

  async refreshDeterminedStaysData() {
    const visibleDateRange = this.store.selectSnapshot(
      ShiftTimelineDataState.visibleDateRange,
    );
    const visibleUserIdsSet = await firstValueFrom(
      this.shiftTimelineVisibilityStateService.visibleUserIdsSet$,
    );

    return firstValueFrom(
      this.fetchNecessaryStaysInRange({
        dateRange: visibleDateRange as { start: Date; end: Date },
        userIds: Array.from(visibleUserIdsSet),
      }),
    ).then(() => {
      firstValueFrom(
        this.fetchValidateAndStoreUserStays({
          dateRange: visibleDateRange as { start: Date; end: Date },
          userIds: Array.from(visibleUserIdsSet),
        }),
      );
    });
  }

  private getNecessaryStaysForDateRange({
    dateRange,
    userIds,
  }: {
    dateRange: {
      start: Date;
      end: Date;
    };
    userIds: string[];
  }) {
    const correctedInterval = getTimeZoneCorrectedDateRange({
      start: dateRange.start as Date,
      end: dateRange.end as Date,
    });

    return this.timelineStayGateway.fetchNecessaryStaysInRange({
      dateRange: {
        start: new Date(correctedInterval.start),
        end: new Date(correctedInterval.end),
      },
      userIds: userIds,
    });
  }

  public updateSingleUserNecessaryStaysInRange({
    dateRange,
    userIds,
  }: {
    dateRange: {
      start: Date;
      end: Date;
    };
    userIds: string[];
  }) {
    try {
      return this.getNecessaryStaysForDateRange({
        dateRange,
        userIds,
      }).pipe(
        tap((response) => {
          const duplicatedRecommendedStaysInRange =
            this.shiftsRecommendedStaysInRangeMap;
          if (duplicatedRecommendedStaysInRange) {
            Object.entries(response).map(
              ([key, value]) =>
                (duplicatedRecommendedStaysInRange[key] = value),
            );
          }
          this.shiftsRecommendedStaysInRangeMap =
            duplicatedRecommendedStaysInRange;
        }),
      );
    } catch (error) {
      console.error(
        'An error occurred while updating user determining necessary stays in range:',
        error,
      );
      this.message.error(
        this.translate.instant('general.something_went_wrong'),
      );
      throw error;
    }
  }

  public fetchNecessaryStaysInRange({
    dateRange,
    userIds,
  }: {
    dateRange: {
      start: Date;
      end: Date;
    };
    userIds: string[];
  }) {
    try {
      return this.getNecessaryStaysForDateRange({
        dateRange,
        userIds,
      }).pipe(
        tap((response) => {
          this.shiftsRecommendedStaysInRangeMap = response;
        }),
      );
    } catch (error) {
      console.error(
        'An error occurred while determining necessary stays in range:',
        error,
      );
      this.message.error(
        this.translate.instant('general.something_went_wrong'),
      );
      throw error;
    }
  }

  public fetchValidateAndStoreUserStays({
    dateRange,
    userIds,
  }: {
    dateRange: {
      start: Date;
      end: Date;
    };
    userIds: string[];
  }) {
    try {
      const correctedInterval = getTimeZoneCorrectedDateRange({
        start: dateRange.start as Date,
        end: dateRange.end as Date,
      });
      return this.timelineStayGateway
        .getStays({
          startDatetime: correctedInterval.start.toISOString(),
          endDatetime: correctedInterval.end.toISOString(),
          userIds,
        })
        .pipe(
          tap((stays) => {
            if (stays.length) {
              this.store.dispatch(
                new TimelineUpdateUserStays({
                  stays,
                  userIds,
                  dates: correctedInterval,
                }),
              );
              const stayIds: string[] = stays.map((stay) => stay.id);
              firstValueFrom(this.validateStays(stayIds));
            }
          }),
        );
    } catch (error) {
      console.error(
        'An error occurred while fetching stays or validating them in range:',
        error,
      );

      this.message.error(
        this.translate.instant('general.something_went_wrong'),
      );
      throw error;
    }
  }

  public validateStays(stayIds: StayId[]) {
    try {
      return this.timelineStayGateway.validateStays(stayIds).pipe(
        tap((response) => {
          this.validatedStaysInRangeMap = response;
        }),
      );
    } catch (error) {
      console.error(
        'An error occurred while validating stays in range:',
        error,
      );

      this.message.error(
        this.translate.instant('general.something_went_wrong'),
      );
      throw error;
    }
  }

  public updateUsersValidatedStays(stayIds: StayId[]) {
    try {
      return this.timelineStayGateway.validateStays(stayIds).pipe(
        tap((response) => {
          const duplicatedValidatedStaysInRange = this.validatedStaysInRangeMap;
          if (duplicatedValidatedStaysInRange) {
            Object.entries(response).map(
              ([key, value]) => (duplicatedValidatedStaysInRange[key] = value),
            );
          }
          this.validatedStaysInRangeMap = duplicatedValidatedStaysInRange;
        }),
      );
    } catch (error) {
      console.error(
        'An error occurred while updating users stays stays in range:',
        error,
      );

      this.message.error(
        this.translate.instant('general.something_went_wrong'),
      );
      throw error;
    }
  }
}
