export enum TabRoute {
  Home = '/',
  Activities = '/activities/unassigned',
  OpenActivities = '/activities/open',
  Services = '/services',
  Servicesv2 = '/services/v2',
  ServiceSeries = '/service-series',
  ServiceTemplates = '/service-templates',
  ServiceSeriesv2 = '/service-series/v2',
  Shifts = '/shifts',
  ShiftSeries = '/shift-series',
  ShiftPlan = '/shift-plan',
  ShiftTemplates = '/shift-templates',
  ShiftDisposition = '/shift-disposition',
  ShiftTimeline = '/shift-timeline',
  OperativeReports = '/operative-reports',
  OperationsBoard = '/operations-board',
  Absences = '/absences',
  Employees = '/employees',
  QualificationsManagement = '/qualifications-management',
  VacationManagement = '/vacation-management',
  VacationManagementv2 = '/vacation-management/v2',
  Locations = '/locations',
  WorkTimeManagement = '/work-time-management',
  Payroll = '/payroll',
  Overtime = '/overtime',
  Settings = '/settings',
  Invoicing = '/invoicing',
  Invoices = '/invoices/overview',
  Share = '/share',
  MyJobs = '/share/jobs/client',
  ExternalJobs = '/share/jobs/provider',
  Projects = '/projects',
  Stays = '/stays',
}
