/* eslint-disable @nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivityTemplate } from '@wilson/interfaces';
import { BucketType } from '@wilson/share/interfaces';
import { ShareRequestStateHelperService } from '@wilson/share/state';
import { isBefore } from 'date-fns';

@Component({
  selector: 'wilson-share-activity-template',
  templateUrl: './share-activity-template.component.html',
  styleUrls: ['./share-activity-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareActivityTemplateComponent {
  @Input() activityTemplate: ActivityTemplate;
  @Input() disabled = false;
  @Output() shareStatusChanged = new EventEmitter<void>();

  get isIconDisabled() {
    return (
      this.activityTemplate?.jobActivityTemplates?.length ||
      this.isServiceSeriesInThePast() ||
      this.activityTemplate?.shiftSeriesId ||
      this.disabled
    );
  }

  get tooltipText() {
    if (this.isServiceSeriesInThePast()) {
      return 'wilson.share.activity_template_in_past_info';
    }
    if (this.activityTemplate?.jobActivityTemplates?.length) {
      return 'wilson.share.cannot_add_activity_template_to_bucket';
    }
    if (this.activityTemplate?.shiftSeriesId) {
      return 'wilson.share.cannot_add_activity_template_to_bucket.shift_series';
    }
  }

  constructor(private shareService: ShareRequestStateHelperService) {}

  async addActivityTemplateToBucket(activityTemplate: ActivityTemplate) {
    const added = await this.shareService.verifyWithUserAndAddItemsToBucket(
      this.activityTemplate.serviceSeriesId,
      [activityTemplate],
      BucketType.ServiceSeries,
    );
    if (added) {
      this.shareStatusChanged.emit();
    }
  }

  removeActivityTemplateFromBucket(id: string) {
    this.shareService.removeItemFromBucket(
      this.activityTemplate.serviceSeriesId,
      id,
    );
    this.shareStatusChanged.emit();
  }

  isServiceSeriesInThePast() {
    return isBefore(
      new Date(this.activityTemplate?.serviceSeries?.validTill),
      new Date(),
    );
  }
}
