import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivitySelectionListItem } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activity-category-and-name-renderer',
  template: `<wilson-activity-category-and-name
    [activityName]="activityName"
    [activityCategory]="cellValue"
  ></wilson-activity-category-and-name>`,
  styleUrls: ['./activity-category-and-name-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCategoryAndNameRendererComponent
  implements ICellRendererAngularComp
{
  public cellValue!: string;
  public activityName: string;

  async agInit(params: ICellRendererParams) {
    this.cellValue = (
      params.data as ActivitySelectionListItem
    ).activityCategory?.name;
    this.activityName = (params.data as ActivitySelectionListItem).name;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = (
      params.data as ActivitySelectionListItem
    ).activityCategory?.name;
    this.activityName = (params.data as ActivitySelectionListItem).name;
    return true;
  }
}
