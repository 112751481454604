import { ActivityTemplate } from '@wilson/interfaces';

export function createFullActivityTemplateObject(
  partialActivityTemplate?: Partial<ActivityTemplate>,
): ActivityTemplate {
  return {
    activityCategoryId: '',
    activityTemplateQualifications: null,
    endLocationId: '',
    name: '',
    serviceSeriesId: null,
    shiftSeriesId: null,
    startLocationId: '',
    viaLocationIds: null,
    startDatetime: '',
    endDatetime: '',
    startTimeZone: 'Europe/Berlin', //Temporary hardcode until we implement dynamic timezone resolution
    endTimeZone: 'Europe/Berlin', //Temporary hardcode until we implement dynamic timezone resolution
    cargo: null,
    track: null,
    note: null,
    externalNumber: null,
    ...partialActivityTemplate,
  };
}
