import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService, TypeOrmFindManyOptions } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  ActivityTemplate,
  OrganizationalUnit,
  PartialServiceSeries,
  SeriesRule,
  ServiceSeries,
  ServiceSeriesModel,
  ShiftSeries,
  WeekDaysEnum,
} from '@wilson/interfaces';
import { createRelationsQueryParamString } from '@wilson/utils';
import { stringify } from 'qs';
import { firstValueFrom, map, Observable, take } from 'rxjs';

export interface UpdateActivityTemplateDTO {
  name: string;
  startDatetime: string;
  endDatetime: string;
  activityCategoryId: string;
  startLocationId: string;
  endLocationId: string;
  viaLocationIds: string[] | null;
  shiftSeriesId: string | null;
  serviceSeriesId: string | null;
  startTimeZone: string;
  endTimeZone: string;
  cargo: string | null;
  track: string | null;
  note: string | null;
  externalNumber: string | null;
}

export interface UpdateServiceSeriesDTO {
  id: string;
  name: string;
  validFrom: string;
  validTill: string;
  onPublicHoliday: boolean;
  recurringOn: WeekDaysEnum[];
  organizationalUnitId: OrganizationalUnit['id'];
  activityTemplates: Partial<UpdateActivityTemplateDTO>[];
}

@Injectable({
  providedIn: 'root',
})
export class ServiceSeriesGateway extends BackendService<ServiceSeries> {
  protected readonly path = 'service-series';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  createServiceSeries(serviceSeries: ServiceSeries[]) {
    return firstValueFrom(
      this.http.post<ServiceSeries[]>(`${this.config.host}/service-series`, {
        items: serviceSeries,
      }),
    );
  }

  updateServiceSeries(serviceSeries: Partial<UpdateServiceSeriesDTO>) {
    return firstValueFrom(
      this.http.patch<ServiceSeries[]>(
        `${this.config.host}/service-series/${serviceSeries.id}`,
        serviceSeries,
      ),
    );
  }

  updatePartialServiceSeries(
    serviceSeries: PartialServiceSeries,
    serviceSeriesId: string,
  ) {
    return firstValueFrom(
      this.http.patch<ServiceSeries[]>(
        `${this.config.host}/service-series/${serviceSeriesId}/edit-services`,
        serviceSeries,
      ),
    );
  }

  getResolvedValidServiceSeriesByDate(selectedDate: string) {
    return this.http.post<ServiceSeries[]>(
      `${this.config.host}/service-series/resolved`,
      { validFrom: selectedDate },
    );
  }

  getServiceSeriesById(serviceSeriesId: string) {
    return this.http.get<ServiceSeries>(
      `${this.config.host}/service-series/${serviceSeriesId}`,
    );
  }

  getResolvedServiceSeriesById(serviceSeriesId: string) {
    return this.http.post<ServiceSeries[]>(
      `${this.config.host}/service-series/resolved`,
      { id: serviceSeriesId },
    );
  }

  getResolvedServiceSeriesByIds(serviceSeriesIds: string[]) {
    return this.http.post<
      (ServiceSeriesModel & {
        seriesRules: SeriesRule[];
        organizationalUnit: OrganizationalUnit;
        activityTemplates: ActivityTemplate[];
      })[]
    >(`${this.config.host}/service-series/resolved`, { id: serviceSeriesIds });
  }

  getServiceSeriesWithRelationById(
    serviceSeriesId: string,
    options?: TypeOrmFindManyOptions,
  ) {
    const params = stringify(options);
    return this.http.get<ServiceSeries[]>(
      `${this.config.host}/service-series?id=${serviceSeriesId}&${params}`,
    );
  }

  public getResolvedServiceSeries(
    shiftSeries: ShiftSeries,
  ): Observable<ServiceSeries[]> {
    let recurringOn = '';
    shiftSeries.recurringOn?.forEach((day) => {
      recurringOn = recurringOn + '&recurringOn[]=' + day;
    });
    const query = `validFrom=${shiftSeries.validFrom}&validTill=${shiftSeries.validTill}&onPublicHoliday=${shiftSeries.onPublicHoliday}`;
    const relations = createRelationsQueryParamString([
      'activityTemplates',
      'activityTemplates.agreement',
      'activityTemplates.agreement.client',
      'activityTemplates.activityCategory',
      'activityTemplates.startLocation',
      'activityTemplates.endLocation',
      'activityTemplates.serviceSeries',
      'activityTemplates.serviceSeries.organizationalUnit',
      'activityTemplates.serviceSeries.seriesRules',
      'activityTemplates.profession',
      'activityTemplates.jobActivityTemplates',
    ]);
    const queryParams = recurringOn
      ? query + relations + recurringOn
      : query + relations;
    return this.http.get<ServiceSeries[]>(
      `${this.config.host}/service-series?${queryParams}`,
    );
  }

  getResolvedActivityTemplates(shiftSeries: ShiftSeries) {
    return this.getResolvedServiceSeries(shiftSeries).pipe(
      map((serviceSeries: ServiceSeries[]) => {
        const activityTemplates: ActivityTemplate[] = [];
        for (const series of serviceSeries) {
          if (series.activityTemplates) {
            for (const template of series.activityTemplates) {
              activityTemplates.push(template);
            }
          }
        }

        return activityTemplates;
      }),
    );
  }

  public deleteServiceSeries(serviceSeriesId: string) {
    return firstValueFrom(
      this.http.delete(`${this.config.host}/service-series/${serviceSeriesId}`),
    );
  }

  public getServiceSeriesForDetailsPage(serviceSeriesId: string) {
    return this.getServiceSeriesWithRelationById(serviceSeriesId, {
      relations: [
        'organizationalUnit',
        'activityTemplates',
        'seriesRules',
        'serviceSeriesEditLogs',
        'activityTemplates.serviceSeries',
        'activityTemplates.shiftSeries',
        'activityTemplates.jobActivityTemplates',
        'activityTemplates.activityCategory',
        'activityTemplates.agreement',
        'activityTemplates.profession',
        'activityTemplates.agreement.client',
        'activityTemplates.startLocation',
        'activityTemplates.endLocation',
        'activityTemplates.activityTemplateQualifications',
      ],
    }).pipe(take(1));
  }
}
