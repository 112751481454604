import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  ActivitiesGateway,
  RequestForProposalGateway,
  PartialActivityWhereInput,
} from '@wilson/api/gateway';
import { FeatureFlagsService } from '@wilson/feature-flags';
import {
  ActivitiesJob,
  Activity,
  ActivitySelectionListItem,
  OperationStatus,
  RequestForProposal,
} from '@wilson/interfaces';
import { endOfDay, startOfDay } from 'date-fns';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { flatten } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  Observable,
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  switchMap,
} from 'rxjs';

interface MappedActivitySelectionItems {
  data: ActivitySelectionListItem[];
}

@Injectable({
  providedIn: 'root',
})
export class ActivitiesDataService {
  constructor(
    private store: Store,
    private readonly requestForProposalGatewayService: RequestForProposalGateway,
    private readonly toastMessage: NzMessageService,
    private readonly translateService: TranslateService,
    private activitiesGateway: ActivitiesGateway,
    private readonly featureFlagsService: FeatureFlagsService,
  ) {}

  public loadUnassignedActivities({
    startDate,
    endDate,
    includeActivitiesFromJobs,
    activityRelations,
    filterOutActivitiesInRequestForProposal,
    idsOfItemsInTheBucket,
    customErrorCallback,
  }: {
    startDate: Date | null;
    endDate: Date | null;
    includeActivitiesFromJobs: boolean;
    activityRelations: string[];
    filterOutActivitiesInRequestForProposal?: boolean;
    idsOfItemsInTheBucket: string[];
    customErrorCallback?: (error: HttpErrorResponse) => void;
  }) {
    const jobRelations = [
      'job',
      'job.activities',
      'job.activities.service',
      'job.activities.activityCategory',
      'job.activities.endLocation',
      'job.activities.startLocation',
      'job.activities.profession',
      'job.organizationalUnit',
      'organizationalUnit',
    ];

    if (startDate && endDate) {
      const where: PartialActivityWhereInput = {
        startDatetime: {
          gte: startOfDay(startDate).toISOString(),
          lte: endOfDay(endDate).toISOString(),
        },
        shiftId: 'null',
        serviceId: 'not-null',
      };
      return combineLatest([
        this.fetchAndTransformJobActivities(
          startDate,
          endDate,
          includeActivitiesFromJobs,
          jobRelations,
          customErrorCallback,
        ),
        this.fetchAndTransformServiceActivities(
          where,
          includeActivitiesFromJobs,
          activityRelations,
          customErrorCallback,
        ),
      ]).pipe(
        map(
          ([{ data: jobActivities }, { data: myActivities }]: [
            MappedActivitySelectionItems,
            MappedActivitySelectionItems,
          ]) => {
            const joinedList = jobActivities.concat(
              myActivities,
            ) as ActivitySelectionListItem[];

            let activities = filterOutActivitiesInRequestForProposal
              ? this.filterActivitiesNotAlreadyInShareJob(
                  joinedList,
                  idsOfItemsInTheBucket,
                )
              : joinedList;
            activities = this.filterActivitiesNotAlreadyInShift(activities);
            activities = this.filterOutCancelledActivities(activities);
            return activities;
          },
        ),
      );
    } else {
      return of([]);
    }
  }

  public loadActivities(
    startDate: Date | null,
    endDate: Date | null,
    includeActivitiesFromJobs: boolean,
    idsOfItemsInTheBucket: string[],
  ) {
    const jobRelations = [
      'job',
      'job.activities',
      'job.activities.service',
      'job.activities.activityCategory',
      'job.activities.endLocation',
      'job.activities.startLocation',
      'job.activities.profession',
      'job.organizationalUnit',
      'organizationalUnit',
      'job.activities.shift',
      'job.activities.shift.user',
    ];
    const activityRelations = [
      'activityCategory',
      'service',
      'service.labels',
      'serviceDeviations',
      'profession',
      'startLocation',
      'endLocation',
      'shift',
      'shift.user',
      'job',
      'job.organizationalUnit',
      'job.requestsForProposals',
    ];
    if (startDate && endDate) {
      const where: PartialActivityWhereInput = {
        startDatetime: {
          gte: startOfDay(startDate).toISOString(),
          lte: endOfDay(endDate).toISOString(),
        },
      };
      return combineLatest([
        this.fetchAndTransformJobActivities(
          startDate,
          endDate,
          includeActivitiesFromJobs,
          jobRelations,
        ),
        this.fetchAndTransformServiceActivities(
          where,
          includeActivitiesFromJobs,
          activityRelations,
        ),
      ]).pipe(
        map(
          ([{ data: jobActivities }, { data: myActivities }]: [
            MappedActivitySelectionItems,
            MappedActivitySelectionItems,
          ]) => {
            const jobActivityIds = jobActivities.map((activity) => activity.id);
            const joinedList = myActivities.reduce(
              (accumulator, activity) => {
                if (!jobActivityIds.includes(activity.id)) {
                  accumulator.push(activity);
                }
                return accumulator;
              },
              [...jobActivities],
            );
            let activities = this.filterActivitiesNotAlreadyInShareJob(
              joinedList,
              idsOfItemsInTheBucket,
            );
            activities = this.filterOutCancelledActivities(joinedList);
            return activities;
          },
        ),
      );
    } else {
      return of([]);
    }
  }

  private fetchAndTransformJobActivities(
    startDate: Date,
    endDate: Date,
    includeActivitiesFromJobs: boolean,
    relations: string[],
    customErrorCallback?: (error: HttpErrorResponse) => void,
  ): Observable<MappedActivitySelectionItems> {
    if (!includeActivitiesFromJobs) {
      return of({
        data: [],
      });
    } else {
      return this.requestForProposalGatewayService
        .getRequestsForProposalsInRange({
          relations,
          where: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        })
        .pipe(
          map(
            (
              requestForProposals: RequestForProposal[],
            ): MappedActivitySelectionItems => {
              return {
                data: flatten(
                  requestForProposals.map(
                    (request): ActivitySelectionListItem[] => {
                      const job = request.job as ActivitiesJob;
                      return job.activities.map(
                        (activity): ActivitySelectionListItem => ({
                          ...(activity as Activity),
                          requestForProposalId: request?.id as string,
                          jobName: job.name,
                          jobOriginOrgUnit: job.organizationalUnit
                            ?.name as string,
                          jobId: job.id ? job.id : null,
                          jobOrganizationalUnitId:
                            job.organizationalUnitId as string,
                        }),
                      );
                    },
                  ),
                ),
              };
            },
          ),
          catchError(
            (
              e: HttpErrorResponse,
            ): Observable<MappedActivitySelectionItems> => {
              if (customErrorCallback) {
                customErrorCallback(e);
              } else {
                this.toastMessage.error(
                  this.translateService.instant('general.error'),
                );
              }
              return of({ data: [] });
            },
          ),
        );
    }
  }

  private fetchAndTransformServiceActivities(
    where: PartialActivityWhereInput,
    includeActivitiesFromJobs: boolean,
    relations: string[],
    customErrorCallback?: (error: HttpErrorResponse) => void,
  ): Observable<MappedActivitySelectionItems> {
    return this.featureFlagsService.flag$
      .pipe(
        map(
          (flags: LDFlagSet) =>
            flags['portal-location-filter-unassigned-activities'],
        ),
        distinctUntilChanged(),
      )
      .pipe(
        switchMap((featureFlagValue) => {
          where = {
            ...where,
            ...(featureFlagValue.length && { OR: featureFlagValue }),
          };

          return this.activitiesGateway.getActivities({
            options: {
              relations,
              where,
              pageNumber: 1,
              pageSize: 9999,
              orderBy: {
                startDatetime: 'asc',
              },
            },
          });
        }),
        map((activities) => activities.data),
      )
      .pipe(
        map((resolvedActivities: Activity[]): MappedActivitySelectionItems => {
          let filteredActivities = resolvedActivities;
          if (!includeActivitiesFromJobs) {
            filteredActivities = resolvedActivities.filter(
              (activity) => !activity.jobId,
            );
          }
          return {
            data: filteredActivities.map(
              (resolvedActivity): ActivitySelectionListItem => ({
                ...resolvedActivity,
                jobId: resolvedActivity.jobId || null,
                jobName: resolvedActivity.job?.name || '',
                jobOriginOrgUnit:
                  resolvedActivity.job?.organizationalUnit?.name || '',
                jobOrganizationalUnitId:
                  resolvedActivity.job?.organizationalUnitId || '',
                requestForProposalId: '',
              }),
            ),
          };
        }),
        catchError(
          (e: HttpErrorResponse): Observable<MappedActivitySelectionItems> => {
            if (customErrorCallback) {
              customErrorCallback(e);
            } else {
              this.toastMessage.error(
                this.translateService.instant('general.error'),
              );
            }
            return of({ data: [] });
          },
        ),
      );
  }

  private filterActivitiesNotAlreadyInShareJob(
    activities: ActivitySelectionListItem[],
    idsOfItemsInTheBucket: string[],
  ): ActivitySelectionListItem[] {
    return activities.filter(
      (activity) => !idsOfItemsInTheBucket.includes(activity.id as string),
    );
  }

  private filterActivitiesNotAlreadyInShift(
    activities: ActivitySelectionListItem[],
  ) {
    return activities.filter((activity) => !activity.shiftId);
  }

  private filterOutCancelledActivities(
    activities: ActivitySelectionListItem[],
  ) {
    return activities.filter(
      (activity) => activity.operationalStatus !== OperationStatus.Cancelled,
    );
  }
}
