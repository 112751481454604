import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { ConfigModule } from '@wilson/config';

@NgModule({
  imports: [CommonModule, HttpClientModule, ConfigModule],
  providers: [AuthService],
})
export class AuthCoreModule {}
