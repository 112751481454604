import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-add-or-remove-check-box-renderer',
  template: `
    <div class="d-flex">
      <span
        class="px-2 selected-icon text-secondary"
        [ngClass]="cellValue ? 'fas fa-minus-circle' : 'fas fa-plus-circle'"
      >
      </span>
    </div>
  `,
  styles: [
    `
      .selected-icon {
        font-size: 22px;
      }
      .selected-icon:hover {
        color: var(--primary-tint) !important;
        cursor: pointer;
      }
    `,
  ],
})
export class AddOrRemoveCheckBoxRendererComponent
  implements AgRendererComponent
{
  public cellValue: boolean;

  agInit(params: ICellRendererParams) {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  private getValueToDisplay(params: ICellRendererParams) {
    return params?.data?.isAdded;
  }
}
