import {
  BackendService,
  Base,
  TypeOrmFindManyOptions,
  UpdateResult,
} from '@wilson/base';
import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

export abstract class ClientFormService<T extends Base> {
  protected abstract readonly datasource: BackendService<T>;
  protected id!: string;
  public abstract readonly form: FormGroup<ControlsOf<T>>;
  public data$: Observable<T> = of();
  public isSaving$ = new BehaviorSubject(false);
  public isLoading$ = new BehaviorSubject(false);

  initialize(id: string, options?: TypeOrmFindManyOptions): void {
    this.id = id;
    this.isLoading$.next(true);

    this.data$ = this.datasource.get(id, options).pipe(shareReplay(1));

    this.data$
      .pipe(
        take(1),
        // eslint-disable-next-line
        tap((date: any) => {
          const formValues = {
            ...date,
            partnershipId: date.clientPartnership?.partnershipId,
            canEditActivity: date.clientPartnership?.canEditActivity,
            canSplitActivity: date.clientPartnership?.canSplitActivity,
          };

          if (date.clientPartnership?.partnershipId) {
            this.form.controls['partnershipId'].disable();
          }

          this.form.patchValue(formValues);
          this.isLoading$.next(false);
        }),
      )
      .subscribe();
  }

  initializeWithData(entity: T): void {
    this.id = entity.id;
    this.form.patchValue(entity);
  }

  submit(): Promise<UpdateResult> | Promise<T[]> {
    this.isSaving$.next(true);
    this.form.disable();

    // eslint-disable-next-line
    const formValues = this.form.getRawValue() as any;

    const clientPartnership = formValues.partnershipId
      ? {
          partnershipId: formValues.partnershipId,
          canEditActivity: formValues.canEditActivity,
          canSplitActivity: formValues.canSplitActivity,
        }
      : null;

    delete formValues.partnershipId;
    delete formValues.canEditActivity;
    delete formValues.canSplitActivity;

    const client = {
      ...formValues,
      clientPartnership,
    };

    let promise: Promise<UpdateResult> | Promise<T[]>;

    if (this.id) {
      promise = this.datasource.update({
        id: this.id,
        ...(client as T),
      });
    } else {
      promise = this.datasource.addMany({ ...client } as T);
    }

    return promise.then((res) => {
      this.form.enable();
      this.isSaving$.next(false);
      return res;
    });
  }
}
