import { MobileSetting, User } from '@wilson/interfaces';

export class InitAuthState {
  static readonly type = '[Auth] InitAuthState';
}

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public email: string, public password: string) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class InitializeUserWithRootOrgUnit {
  static readonly type = '[Auth] InitializeUserWithRootOrgUnit';
}

export class RefreshAccessToken {
  static readonly type = '[Auth] RefreshAccessToken';
}

export class UpdateUser {
  static readonly type = '[Auth] UpdateUser';
  constructor(public user: User) {}
}

export class UpdateMobileSettings {
  static readonly type = '[Auth] UpdateMobileSettings';
  constructor(public mobileSettings: MobileSetting) {}
}

export class InitializeAzureSSOFlow {
  static readonly type = '[Auth] InitializeAzureSSOFlow';
  constructor(public accessToken: string, public refreshToken: string) {}
}

export class SetCurrentlyReplacingAzureTokens {
  static readonly type = '[Auth] SetCurrentlyReplacingAzureTokens';
  constructor(public currentlyReplacingAzureTokens: boolean) {}
}

export class SetLoggingOutUser {
  static readonly type = '[Auth] SetLoggingOutUser';
  constructor(public loggingOutUser: boolean) {}
}
