export const ROW_BORDER_BOTTOM_PX = 1.25;
export const INNER_ROW_PADDING_TOP_PX = 6;
export const INNER_ROW_PADDING_BOTTOM_PX = 6;
export const ACTIVITY_TITLE_HEIGHT_PX = 12;
export const ACTIVITY_TITLE_MARGIN_TOP_PX = 4;
export const ACTIVITY_TITLE_MARGIN_BOTTOM_PX = 4;
export const ACTIVITY_BODY_HEIGHT_PX = 36;
export const ACTIVITY_BODY_MARGIN_BOTTOM_PX = 4;
export const ACTIVITY_BORDER_PX = 1.25;
export const ACTIVITY_LEFT_RIGHT_ADJUSTMENT = 4;

export const ACTIVITY_MODAL_ROW_HEIGHT = 110;
export const COMPACT_ROW_PADDING = 6;
export const COMPACT_ROW_HEIGHT = 70;
export const COMPACT_SHIFT_HEIGHT = 58;
export const COMPACT_ABSENCE_HEIGHT = 58;
export const COMPACT_ACTIVITY_HEIGHT = 18;
export const MINIMAL_ROW_PADDING = 4;
export const MINIMAL_ROW_HEIGHT = 44;
export const MINIMAL_SHIFT_HEIGHT = 36;
export const MINIMAL_ABSENCE_HEIGHT = 58;
export const MINIMAL_ACTIVITY_HEIGHT = 6;
export const MINIMAL_ACTIVITY_ROW_ADJUSTMENT = 2;

export const MIN_SHIFT_WIDTH_PX = 110;
export const SHIFT_FIRST_BREAKPOINT = 95;
export const SHIFT_SECOND_BREAKPOINT = 20;

export const STAYS_TIMELINE_ADJUSTMENT_PIXEL = 2;

export const DRAG_IMAGE_WIDTH = 1000;
export const DRAG_IMAGE_HEIGHT = 60;
export const DRAG_IMAGE_OFFSET_X = 0;
export const DRAG_IMAGE_OFFSET_Y = 25;
export const DRAG_IMAGE_OFFSCREEN_TOP = -9999;
export const DRAG_IMAGE_TIMEOUT = 0;
export const LARGE_ELEMENT_PIXEL_SIZE = 5000;
